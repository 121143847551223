import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  Button,
  CardActions,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import {Add, Ballot, CloudDownload, CloudUpload, Delete, Edit, FileCopy, Print, Refresh} from '@material-ui/icons';
import {useIntl} from 'react-intl';
import {graphQLApi} from 'services/GraphQLApi';
import {authHeader, authUser, getApiDomain, useAuthDispatch} from 'contexts/Auth';
import {FieldTypes, getEntityValueFromFieldType} from 'variables/fields';
import EnhancedTable from 'components/DataTable/EnhancedTable';
import SelectPrintoutDialog from 'views/Entities/components/SelectPrintoutDialog';
import {getLayouts, rolePriorities} from 'config';
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import moment from 'moment';
import Sidebar from '../../components/Sidebar/Sidebar';
import Clear from '@material-ui/icons/Clear';
import {renderThumbNails,} from '../../components/FileTypes';
import theme from '../../assets/theme/theme';
import {LanguageContext} from '../../contexts/Layouts';
import {useNavigate, useParams} from "react-router-dom";

export default function EntityList(props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();
  const tableRef = useRef();

  let entityTypeID = params?.entityTypeID;
  let viewID = params?.viewID;
  const {language, setLanguage} = useContext(LanguageContext);

  const [languages, setLanguages] = useState([]);
  const [settings, setSettings] = useState({revisions: '0'});
  const [entityType, setEntityType] = useState(null);
  const [view, setView] = useState(null);
  const [columns, setColumns] = useState([]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [fields, setFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmDeleteEntities, setShowConfirmDeleteEntities] = useState(false);
  const [showConfirmCopyEntities, setShowConfirmCopyEntities] = useState(false);
  const [showConfirmExportEntities, setShowConfirmExportEntities] = useState(0);
  const [showExportFieldsDialog, setShowExportFieldsDialog] = useState(false);
  const [showImportDialog, setShowImportDialog] = useState(false);
  const [importDialogUploading, setImportDialogUploading] = useState(false);
  const [hasPrintouts, setHasPrintouts] = useState(false);
  const [file, setFile] = useState('');
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const [searchFields, setSearchFields] = useState([]);
  const client = new graphQLApi(useAuthDispatch());

  /**
   * Notification
   */
  const [notification, setNotification] = React.useState({
    severity: 'info',
    show: false,
    message: '',
  });
  const notify = (message, color = 'info') => {
    setNotification({severity: color, message: message, show: true});
  };
  const closeNotification = () => setNotification({...notification, show: false});


  const getRowValue = (row, field) => {
    let pVal = row.values ? row.values.find(pv => pv.field?.id === field.id) : null;
    if (!pVal) return '';
    // TODO need a better way to show related entities in the list
    if (field.type.match(/^entity_.+$/)) {
      return pVal.related_entity?.id;
    }
    if (field.type.match(/^entities_.+$/)) {
      return row.values
                .filter(pv => (pv.field.id === field.id && !!pv.related_entity))
                .map((pv) => pv.related_entity.id).join(', ');
    }
    switch (field.type) {
      case FieldTypes.BOOLEAN:
        return <Icon style={{color: pVal.bool ? 'green' : 'red'}} fontSize={'inherit'}>circle</Icon>;
      case FieldTypes.STRING:
        return pVal.string;
      case FieldTypes.TEXT:
        return pVal.text.substring(0, 50);
      case FieldTypes.HTML:
        return pVal.text === '<p></p>' ? 'Empty' : 'Filled';
      case FieldTypes.PRICE:
        if (pVal.string) {
          return intl.formatNumber(pVal.decimal, {
            style: 'currency',
            currency: pVal.string,
            currencyDisplay: 'code',
            minimumFractionDigits: 2,
          });
        }
        else {
          return intl.formatNumber(pVal.decimal, {
            minimumFractionDigits: 2,
          });
        }
      case FieldTypes.DECIMAL:
        return intl.formatNumber(pVal.decimal);
      case FieldTypes.DATE:
        return pVal.date ? moment(pVal.date).format(intl.formatMessage({id: 'common.date.format'})) : '';
      case FieldTypes.DATETIME:
        return pVal.datetime ? moment(pVal.datetime).format(intl.formatMessage({id: 'common.datetime.format'})) : '';
      case FieldTypes.NUMBER:
        return pVal.integer;
      case FieldTypes.OPTION:
        return pVal.field_option ? pVal.field_option.title : '';
      case FieldTypes.ASSET:
        return renderThumbNails(pVal);
      case FieldTypes.ASSETS:
        return !row.values ? '' :
          row.values
             .filter(pv => (pv.field.id === field.id && pv.string && pv.string !== ''))
             .map((pv, pvIdx) => renderThumbNails(pv, pvIdx));
      case FieldTypes.OPTIONS:
        return !row.values ? '' :
          row.values
             .filter(pv => (pv.field.id === field.id && pv.field_option))
             .map((pv, pvIdx) => <Chip key={'column-' + field.id + '-option-' + pvIdx} size={'small'}
                                       style={{marginLeft: 3}} label={pv.field_option.title}/>);
      case FieldTypes.COMPUTED_COMPLETION:
        return <div style={{display: 'flex', alignItems: 'center', color: pVal.decimal >= 100 ? theme.palette.success.dark : 'inherit'}}>
          <CircularProgress variant="determinate" size={16} value={pVal.decimal}
                            style={{color: pVal.decimal >= 100 ? theme.palette.success.dark : 'inherit'}}/>
          &nbsp;
          <div style={{fontSize: 'smaller', width: '40px', textAlign: 'right'}}>{intl.formatNumber(parseInt(pVal.decimal),
            {unit: '%', unitDisplay: 'narrow'})}%
          </div>
        </div>;
      default:
        return String(getEntityValueFromFieldType({...pVal, field: field})).substring(0, 200);
    }
  };

  const setRowValue = (row, field, value) => {
    let index = row.values.findIndex(pv => pv.field.id === field.id);
    const vars = {
      entity_id: 'ID',
      field_id: 'ID',
    };
    if (index === -1) {
      index = row.values.push({
        entity_id: row.id,
        field: field,
        field_id: field.id,
      }) - 1;
      const lang = languages.find(l => l.locale === language.locale);
      if (lang.id > 0) {
        row.values[index].language_id = lang.id;
        vars.language_id = 'ID';
      }
    }
    else {
      vars.id = 'ID!';
      row.values[index].entity_id = row.id;
      row.values[index].field_id = field.id;
    }
    switch (field.type) {
      case FieldTypes.BOOLEAN:
        row.values[index].bool = value;
        vars.bool = 'Boolean';
        break;
      case FieldTypes.STRING:
        row.values[index].string = value;
        vars.string = 'String';
        break;
      case FieldTypes.TEXT:
      case FieldTypes.HTML:
        row.values[index].text = value;
        vars.text = 'String';
        break;
      case FieldTypes.PRICE:
      case FieldTypes.DECIMAL:
        row.values[index].decimal = value;
        vars.decimal = 'Float';
        break;
      case FieldTypes.DATE:
        row.values[index].date = value;
        vars.date = 'String';
        break;
      case FieldTypes.DATETIME:
        row.values[index].datetime = value;
        vars.datetime = 'String';
        break;
      case FieldTypes.NUMBER:
        row.values[index].integer = value;
        vars.integer = 'Int';
        break;
      case FieldTypes.OPTION:
        row.values[index].field_option = value;
        vars.field_option_id = 'ID';
        row.values[index].field_option_id = value?.id;
        break;
      case FieldTypes.ASSET:
        row.values[index].string = value;
        vars.string = 'String';
        break;
      // case FieldTypes.ASSETS:
      //   return !row.values ? '' :
      //     row.values
      //       .filter(pv => (pv.field.id === field.id && pv.string && pv.string !== ""))
      //       .map((pv, pvIdx) => <img key={"column-" + field.id + "-image-" + pvIdx} height={24}
      //                                style={{marginLeft: 3}} src={getThumbnailFromUrl(pv.string)} alt={pv.string?.split('/').pop()} title={pv.string?.split('/').pop()}/>)
      // case FieldTypes.OPTIONS:
      //   return !row.values ? '' :
      //     row.values
      //       .filter(pv => (pv.field.id === field.id && pv.field_option))
      //       .map((pv, pvIdx) => <Chip key={"column-" + field.id + "-option-" + pvIdx} size={"small"}
      //                                 style={{marginLeft: 3}} label={pv.field_option.title}/>)
      default:
        break;
    }
    client.mutation('entityValue', vars, row.values[index], 'id').then(r => {
      if (r && r.hasOwnProperty('response')) {
        row.values[index].id = r.response.id;
      }
    });
    return row;
  };

  const [importKey, setImportKey] = useState(null);
  const [exportKey, setExportKey] = useState(null);

  let checkedAtTimer = useRef(null)
  useEffect(() => {
    checkedAtTimer.current = setInterval(() => {
      setImportKey(localStorage.getItem('importKey') ?? null);
      setExportKey(localStorage.getItem('exportKey') ?? null);
      return () => {
        clearInterval(checkedAtTimer.current);
      };
    }, 2500);
  }, []);
  useEffect(() => {
    setIsLoading(true);
    if (!entityTypeID || !viewID) {
      setColumns([]);
      setView(null);
      client.query('{' +
        'entityTypes(limit:1' + (entityTypeID > 0 ? 'filter:{id:' + entityTypeID + '}' : '') +
        '){data{id title views{id title listing_id columns{field{id uses_languages type name key is_editable}}}}} ' +
        '}').then(r => {
        if (r?.entityTypes && r.entityTypes.data) {
          setEntityType(r.entityTypes.data[0]);
          if (r.entityTypes.data[0]?.id && r.entityTypes.data[0].views[0]?.id) {
            navigate('/data/' + r.entityTypes.data[0].id + '/view/' + r.entityTypes.data[0].views[0].id, {replace: true});
          }
        }
        setIsLoading(false);
      });
      return;
    }
    client.query('{' +
      'entityTypes(filter:{id:' + entityTypeID +
      '},limit:1){data{id title fields{id name} views{id title listing_id columns{id column field{id uses_languages type name key is_editable}}}}} ' +
      // 'entities(filter:{entity_type_id:' + entityTypeID + ' parent_id_ne:null},limit:1){total} ' +
      'printoutLayouts{total} ' +
      'languages{data{id name locale country_code}} ' +
      'settings(filter:{key_in:["locale","language","country_code", "revisions"]}){data{id key data}} ' +
      '}')
          .then(r => {
            let cols = [];
            if (r?.hasOwnProperty('printoutLayouts')) {
              setHasPrintouts(r.printoutLayouts.total > 0);
            }
            if (r?.hasOwnProperty('entityTypes')) {
              setEntityType(r.entityTypes.data[0]);
              setView(r.entityTypes.data[0].views.find(v => v.id === viewID));
              setFields(r.entityTypes.data[0].fields?.sort((a, b) => a.name.localeCompare(b.name)));
            }
            if (r?.hasOwnProperty('languages') && r?.hasOwnProperty('settings')) {
              const setts = {};
              r.settings.data.forEach(s => {
                setts[s.key] = s.data;
              });
              setSettings(setts);
              r.languages.data.unshift({
                id: '0',
                locale: setts.locale,
                name: setts.language,
                country_code: setts.country_code,
              });
              setLanguages(r.languages.data);
            }
            if (r && r.hasOwnProperty('entityTypes')) {
              r.entityTypes.data[0].views.find(v => v.id === viewID)?.columns.forEach(c => {
                switch (c.column) {
                  case 'id':
                    cols.push({
                      field: c.column,
                      title: intl.formatMessage({id: 'entities.list.column.id', defaultMessage: '#ID'}),
                      align: 'left',
                      render: row => row.id,
                    });
                    return;
                  case 'parent_id':
                    cols.push({
                      field: c.column,
                      title: intl.formatMessage({id: 'entities.list.column.parent_id', defaultMessage: 'Parent #ID'}),
                      align: 'left',
                      render: row => row.parent?.id,
                    });
                    return;
                  case 'variant_fields':
                    cols.push({
                      title: intl.formatMessage({id: 'entities.list.column.variant_fields', defaultMessage: 'Variant fields'}),
                      field: 'variant_fields',
                      render: row => {
                        if (row.parent) {
                          return row.parent.variant_fields.map(f => {
                            let value = row.values.find(pv => pv.field.id === f.id);
                            if (value && value.field_option) {
                              return (<Chip key={'variant_field_' + f.id + '_value_' + value.id + '_' + row.id}
                                            style={{marginRight: 3}} size={'small'} variant={'outlined'}
                                            label={value.field_option.title}/>);
                            }
                            return row.name;
                          });
                        }
                        else if (row.variant_fields && row.variant_fields.length) {
                          return row.variant_fields.map(f => {
                            return (<Chip key={'variant_field_' + f.id + '_' + row.id} style={{marginRight: 3}} size={'small'}
                                          variant={'outlined'} label={f.name}/>);
                          });
                        }
                        return '';
                      },
                    });
                    return;
                  default:
                }
                if (c.field === null) console.error('Unknown column type detected!', c);
                const f = c.field;
                let type;
                switch (f.type) {
                  case FieldTypes.BOOLEAN:
                    type = 'boolean';
                    break;
                  case FieldTypes.NUMBER:
                  case FieldTypes.DECIMAL:
                    type = 'number';
                    break;
                  case FieldTypes.PRICE:
                    type = 'currency';
                    break;
                  default:
                    type = 'string';
                    break;
                }
                cols.push({
                  field: 'field.' + f.id,
                  title: f.name,
                  sorting: false,
                  search: false,
                  type: type,
                  align: [FieldTypes.PRICE, FieldTypes.DECIMAL, FieldTypes.NUMBER].findIndex(t => t === f.type) === -1 ? 'left' : 'right',
                  render: row => getRowValue(row, f),
                  set: (f.is_editable && authUser().isAllowed(rolePriorities.can_data) && settings?.revisions === '0') ?
                    (row, value) => setRowValue(row, f, value) :
                    undefined,
                });
              });
            }
            setColumns(cols);
            setIsLoading(false);
          });
  }, [entityTypeID, viewID]);

  const [showSelectLayout, setShowSelectLayout] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState({});
  const actions = [
    {
      tooltip: intl.formatMessage({id: 'common.button.edit', defaultMessage: 'Edit'}),
      icon: Edit,
      rowClick: true,
      onClick: row => {
        setSelectedEntity(row);
        navigate('/data/' + entityTypeID + '/view/' + viewID + '/entity/' + row.id);
      },
    },
    {
      tooltip: intl.formatMessage({id: 'common.button.create', defaultMessage: 'Create'}),
      icon: Add,
      isFreeAction: true,
      onClick: () => {
        navigate('/data/' + entityTypeID + '/view/' + viewID + '/entity/create');
      },
    },
    {
      tooltip: intl.formatMessage({id: 'common.button.refresh', defaultMessage: 'Refresh'}),
      icon: Refresh,
      isFreeAction: true,
      onClick: () => {
        tableRef.current.update();
      },
    },
  ];

  const handleExportResponse = (response) => {
    setSelectedFields([]);
    setShowExportFieldsDialog(false);
    tableRef.current?.isLoading(false);
    if (response) {
      const count = selectedEntities.length > 0 ? selectedEntities.length : total;
      const key = JSON.stringify({key: response.entityExportToCsv, total: count});
      localStorage.setItem('exportKey', key);
    }
  };

  if (hasPrintouts) {
    actions.unshift({
      tooltip: intl.formatMessage({id: 'common.button.print', defaultMessage: 'Print'}),
      icon: Print,
      onClick: row => {
        setShowSelectLayout(true);
        setSelectedEntity(row);
      },
    });
  }

  if (authUser().isAllowed(rolePriorities.can_data)) {
    let languageName = languages.find(l => l.locale === language.locale)?.name;
    actions.push(
      {
        icon: CloudUpload,
        tooltip: intl.formatMessage({
          id: 'entities.list.actions.export_selected',
          defaultMessage: 'Export selected data on {language} to a file',
        }, {language: languageName}),
        onSelected: true,
        disabled: exportKey !== null,
        onClick: (rows) => {
          setSelectedEntities(rows);
          setShowExportFieldsDialog(true);
          // setShowConfirmExportEntities(getIdsAndChildrenIds(rows).length);
        },
      });
    actions.push({
      icon: CloudDownload,
      tooltip: intl.formatMessage({
        id: 'entities.list.actions.export',
        defaultMessage: 'Export data on {language} to a file',
      }, {language: languageName}),
      isFreeAction: true,
      disabled: exportKey !== null,
      onClick: () => {
        setShowExportFieldsDialog(true);
      },
    });
    actions.push({
      icon: CloudUpload,
      tooltip: intl.formatMessage({
        id: 'entities.list.actions.import',
        defaultMessage: 'Import data on {language} from a file',
      }, {language: languageName}),
      isFreeAction: true,
      disabled: importKey !== null,
      onClick: () => {
        setShowImportDialog(true);
      },
    });
    actions.push({
      icon: Delete,
      tooltip: intl.formatMessage({id: 'entities.list.actions.delete_selected', defaultMessage: 'Delete selected'}),
      onSelected: true,
      onClick: (rows) => {
        setSelectedEntities(rows);
        setShowConfirmDeleteEntities(true);
      },
    });
    actions.push({
      icon: FileCopy,
      tooltip: intl.formatMessage({id: 'entities.list.actions.copy_selected', defaultMessage: 'Copy selected'}),
      onSelected: true,
      onClick: (rows) => {
        setSelectedEntities(rows);
        setShowConfirmCopyEntities(true);
      },
    });
  }

  const onDeleteEntitiesConfirm = (confirmed) => {
    if (confirmed) {
      client.mutate('($ids:[ID]!) {entityDelete(ids:$ids)}',
        {ids: selectedEntities.map(r => parseInt(r.id))}).then(_r => {
        tableRef.current.update();
      });
    }
    setSelectedEntities([]);
    setShowConfirmDeleteEntities(false);
  };

  const onCopyEntitiesConfirm = (confirmed) => {
    if (confirmed) {
      tableRef.current.isLoading(true);
      client.mutate('($ids:[ID]!) {entityCopyCreate(ids:$ids)}',
        {ids: selectedEntities.map(r => parseInt(r.id))}).then(_r => {
        if (_r) {
          notify(intl.formatMessage({
            id: 'entities.list.notification.copy.message',
            defaultMessage: '{count} entities are copied!',
          }, {count: _r.entityCopyCreate}), 'success');
          tableRef.current.update();
        }
      });
    }
    setSelectedEntities([]);
    setShowConfirmCopyEntities(false);
  };

  const getIdsAndChildrenIds = (selectedEntities) => {
    let ids = [];
    selectedEntities.forEach(se => {
      ids.push(se.id);
      se.children?.forEach(child => ids.push(child.id));
    });
    return ids;
  };
  const onExportEntitiesConfirm = (confirmed) => {
    if (confirmed) {
      let ids = getIdsAndChildrenIds(selectedEntities);
      tableRef.current.isLoading(true);
      const fieldIds = selectedFields.length ? 'field_ids:[' + selectedFields?.map(sf => sf.id) + ']' : 'field_ids:[]';
      client.mutate(
        '{entityExportToCsv(' + fieldIds + ', ids:[' + ids + '], entity_type_id:' + entityTypeID + ', locale:"' + language.locale + '")}')
            .then(handleExportResponse);
    }
    setSelectedEntities([]);
    setShowConfirmExportEntities(0);
    tableRef.current.update();
  };

  const handleOnChangeFile = (e) => {
    setFile(e.target.files[0]);
  };
  const handleImportFile = (e) => {
    e.preventDefault();
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('locale', language.locale);
      formData.append('entity_type_id', entityTypeID);
      const headers = authHeader();
      headers['Content-Type'] = 'multipart/form-data';
      setImportDialogUploading(true);
      axios
        .post(getApiDomain() + '/import', formData, {headers: headers})
        .then(result => {
          if (typeof (result.data) === 'object') {
            const key = JSON.stringify({key: result.data.key, total: result.data.total});
            localStorage.setItem('importKey', key);
            notify(intl.formatMessage({
              id: 'entities.list.notification.import.file_uploaded',
              defaultMessage: 'File has been uploaded and processing will start momentarily',
            }));
            setShowImportDialog(false);
          }
          else {
            notify(intl.formatMessage({
              id: result.data ? result.data : 'entities.list.notification.import.failure_message',
              defaultMessage: result.data,
            }), 'warning');
          }
          setImportDialogUploading(false);
          setFile(null);
          document.getElementById("import-file").value = '';
        });
    }
  };

  const handleExport = () => {
    if (selectedEntities.length) {
      setShowConfirmExportEntities(selectedEntities.length);
    }
    else {
      tableRef.current.isLoading(true);
      const fieldIds = selectedFields.length ? 'field_ids:[' + selectedFields?.map(sf => sf.id) + ']' : 'field_ids:[]';
      const searchObject = tableRef.current.getSearch()
      const searchString = searchObject.search.length !== 0 ? ' search: "' + searchObject.search +'", fullTextSearch:' + searchObject.fullTextSearch : ''
      client.mutate(
        '{entityExportToCsv(' + fieldIds + searchString +', listing_id:' + view?.listing_id + ', entity_type_id:' + entityTypeID + ', locale:"' +
        language.locale + '")}').then(handleExportResponse);
    }
  };

  const onSetTotal = (v) => {
    setTotal(v);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    const result = fields.filter(f => f.name.toLowerCase().includes(e.target.value.toLowerCase()));
    setSearchFields(result);
  };

  const onExportCancel = () => {
    setShowExportFieldsDialog(false);
    setSelectedFields([]);
  };

  return (entityTypeID > 0 && <>
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      message={notification.message}
      open={notification.show}
      onClose={closeNotification}
      autoHideDuration={6000}
    />
    {entityType?.views?.length > 0 ? <Sidebar
      {...props}
      routes={[
        {
          title: entityType.title,
          path: '',
        }, ...entityType.views.map(v => ({
          path: 'view/' + v.id,
          name: v.title,
          component: EntityList,
          icon: Ballot,
        }))]}
      bottomLinks={authUser().isAllowed(rolePriorities.can_import_export) && [
        {
          name: getLayouts(intl).admin.title,
          icon: getLayouts(intl).admin.icon,
          path: getLayouts(intl).admin.defaultRoute,
        },
      ]}
      pathPrefix={'/data/' + entityTypeID}
    /> : (entityType ? <>
      <Typography variant="h2">No views found for the entity type {entityType?.title}</Typography>
      <Button onClick={() => navigate('/admin/data-types', {replace: true})}>Go to admin</Button>
    </> : '')}
    {!isLoading ? <Paper style={{marginLeft: 250}}>
        <Snackbar
          open={isLoading}
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          message={'Loading...'}
          action={<CircularProgress color="inherit"/>}
        />
        <ConfirmDialog
          onClose={onDeleteEntitiesConfirm}
          open={showConfirmDeleteEntities}
          title={intl.formatMessage({
            id: 'dropzone.list.confirm.delete_selected_entities.title',
            defaultMessage: 'Confirm you want to delete {count} entities',
          }, {count: selectedEntities.length})}
          message={intl.formatMessage({
            id: 'dropzone.list.confirm.delete_selected_entities.message',
            defaultMessage: 'Are you sure you want to delete all of the {count} selected entities?',
          }, {count: selectedEntities.length})}
        />
        <ConfirmDialog
          onClose={onCopyEntitiesConfirm}
          open={showConfirmCopyEntities}
          title={intl.formatMessage({
            id: 'dropzone.list.confirm.copy_selected.title',
            defaultMessage: 'Confirm you want to copy {count} entities',
          }, {count: selectedEntities.length})}
          message={intl.formatMessage({
            id: 'dropzone.list.confirm.copy_selected.message',
            defaultMessage: 'Are you sure you want to copy all of the {count} selected entities?',
          }, {count: selectedEntities.length})}
        />
        <ConfirmDialog
          onClose={onExportEntitiesConfirm}
          open={showConfirmExportEntities > 0}
          title={intl.formatMessage({
            id: 'dropzone.list.confirm.export_selected.title',
            defaultMessage: 'Confirm you want to export {count} entities',
          }, {count: showConfirmExportEntities})}
          message={intl.formatMessage({
            id: 'dropzone.list.confirm.export_selected.message',
            defaultMessage: 'Are you sure you want to export all of the {count} selected entities?',
          }, {count: showConfirmExportEntities})}
        />
        <Dialog open={showImportDialog}>
          <DialogTitle disableTypography>
            <Typography variant="h2" color="primary">{intl.formatMessage({
              id: 'entities.list.import.file.heading',
              defaultMessage: 'Import file to {language}',
            }, {language: languages.find(l => l.locale === language.locale)?.name})}</Typography>
          </DialogTitle>
          <CardContent>
            <input id="import-file" type={'file'} size="small" onChange={handleOnChangeFile}/>
          </CardContent>
          {importDialogUploading
            ? <CardActions style={{display: 'flex', justifyContent: 'center'}}>
              <CircularProgress/>
            </CardActions>
            : <CardActions style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Box>
                <Button
                  onClick={() => {
                    setShowImportDialog(false);
                  }}
                  variant="outlined"
                  color="default"
                >{
                  intl.formatMessage({
                    id: 'common.button.cancel',
                    defaultMessage: 'Cancel',
                  })
                }</Button>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  onClick={handleImportFile}
                  variant="outlined"
                  color="primary"
                >{
                  intl.formatMessage({
                    id: 'entities.list.import.file.upload',
                    defaultMessage: 'Upload File',
                  })
                }</Button>
              </Box>
            </CardActions>}
        </Dialog>
        <Dialog open={showExportFieldsDialog}>
          <DialogTitle disableTypography>
            <Typography variant="h2" color="primary">{intl.formatMessage({
              id: 'entities.list.export.selected_fields.heading',
              defaultMessage: 'Select fields to export',
            })}</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} justifyContent="flex-end" style={{marginBottom: '5px'}}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label={intl.formatMessage({id: 'enhanced_table.toolbar.search', defaultMessage: 'Search'})}
                  value={search}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: search !== '' ? (
                      <IconButton onClick={() => {
                        setSearch('');
                      }}>
                        <Clear color="disabled" fontSize="small"/>
                      </IconButton>
                    ) : '',
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={selectedFields.length ? 8 : 12}>
                <TableContainer style={{maxHeight: 500, minHeight: 500}}>
                  <Table size="small" stickyHeader>
                    {(search !== '' && searchFields.length === 0) && <TableBody><TableRow><TableCell>{intl.formatMessage({
                      id: 'entities.list.export.search_fields.message',
                      defaultMessage: 'No fields were found',
                    })}</TableCell></TableRow></TableBody>}
                    {search === '' &&
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <FormControlLabel
                              control={<Checkbox
                                name={'fields'}
                                color={'primary'}
                                checked={selectedFields.length === fields.length}
                                onChange={e => e.target.checked ? setSelectedFields(fields) : setSelectedFields([])}
                              />}
                              label={intl.formatMessage({id: 'entities.list.export.dialog.select.fields', defaultMessage: 'Select all'})}/>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    }
                    <TableBody>
                      {(search !== '' ? searchFields : fields)?.map((f, index) => {
                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={index + '-' + f.id}
                          >
                            <TableCell padding="checkbox">
                              <FormGroup>
                                <FormControlLabel
                                  control={<Checkbox
                                    name={'field-' + f.id}
                                    color={'primary'}
                                    checked={selectedFields.includes(f)}
                                    onChange={e => e.target.checked ? setSelectedFields([...selectedFields, f]) :
                                      setSelectedFields(selectedFields.filter(sf => sf.id !== f.id))}
                                  />}
                                  label={f.name}
                                />
                              </FormGroup>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {selectedFields.length > 0 && <Grid item xs={4}>
                <Box style={{border: '1px solid black', maxHeight: 500, padding: 13, overflowY: 'auto', overflowX: 'auto'}}>
                  {selectedFields.map((sf, i) => <Box key={'sf-' + i}>{sf.name}</Box>)}
                </Box>
              </Grid>}
            </Grid>
          </DialogContent>
          <DialogActions style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Box>
              <Button onClick={onExportCancel}
                      variant="outlined" color="default">{intl.formatMessage({id: 'common.button.cancel', defaultMessage: 'Cancel'})}</Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Button variant="outlined" color="primary" onClick={handleExport}>{intl.formatMessage(
                {id: 'entities.list.export.dialog.export', defaultMessage: 'Export'})}</Button>
            </Box>
          </DialogActions>
        </Dialog>

        {columns.length > 0 && <EnhancedTable
          {...props}
          ref={tableRef}
          title={<Grid container style={{justifyContent: 'space-between'}}><Grid item style={{flexGrow: 1}}>{view?.title}</Grid>
            <Grid item>{languages.map((l, k) =>
              <img key={'language-select-' + k}
                   onClick={() => {
                     let newLanguage = {id: l.id > 0 ? l.id : null, locale: l.locale};
                     if (language.id !== newLanguage.id) {
                       setLanguage(newLanguage);
                       tableRef.current.update();
                     }
                   }}
                   src={'/flags/' + l.country_code.toUpperCase() + '.png'}
                   title={l.name}
                   style={{
                     margin: '0 8px',
                     boxShadow: language.locale === l.locale ? '0px 0px 7px grey' : '',
                     cursor: 'pointer',
                   }} alt=""/>,
            )}</Grid>
          </Grid>}
          columns={columns}
          query="entities"
          filter={'language_id:' + languages?.find(l => language.locale === l.locale)?.id + ' ' +
            (view?.listing_id > 0 ? ('listing_id:' + view.listing_id) : ('entity_type_id:' + entityTypeID))}
          actions={actions}
          fields={'id entity_type_id parent{id variant_fields{id}} variant_fields{id name}' +
            ' values(locale:"' + language.locale + '", field_id_in:[' + view?.columns?.filter(c => !!c.field).map(c => c.field.id).join(',') + '])' +
            '{' +
            ' id field{id} language{id} field_option{title} asset{file_thumb} text string integer decimal bool date datetime related_entity{id}' +
            '}'}
          icon={<Ballot/>}
          onSetTotal={onSetTotal}
          children
          urlState
          fullTextSearchable
        />}
        <SelectPrintoutDialog open={showSelectLayout} onClose={() => setShowSelectLayout(false)} entity={selectedEntity}/>
      </Paper> :
      <Grid container justifyContent="center" style={{marginLeft: 250, marginTop: 250}}><Grid
        item><CircularProgress/></Grid></Grid>}
  </>);
}
