import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useIntl} from "react-intl";
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import {Apps, EditAttributes, ImportExport, People} from "@material-ui/icons";
import {Line} from "react-chartjs-2";
import {CategoryScale, Chart as ChartJS, LinearScale, LineElement, PointElement} from 'chart.js';
import componentStyles from "assets/theme/views/admin/dashboard.js";
import {authUser, useAuthDispatch} from "contexts/Auth";
import {graphQLApi} from "services/GraphQLApi";
import themeColors from "assets/theme/colors";

const useStyles = makeStyles(componentStyles);

function Dashboard() {
  const classes = useStyles();
  const intl = useIntl();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(true);
  const [entities, setEntities] = React.useState([]);
  const [channels, setChannels] = React.useState([]);
  const [stats, setStats] = React.useState({
    users: 0,
    roles: 0,
    entities: 0,
    entity_values: 0,
    entity_types: 0,
    fields: 0,
    field_groups: 0,
    channels: 0,
    connectors: 0,
    changes: {
      labels: [],
      datasets: [
        {
          label: '',
          data: [],
        },
      ],
    }
  });

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

  const client = new graphQLApi(useAuthDispatch());
  useEffect(() => {
    client.query('{' +
      'dashboard{users roles entities entity_values entity_types fields field_groups channels connectors changes{date value}}' +
      'channels(limit:10,sorting:"updated_at") {data {id title updated_at}}' +
      'entities(sorting:"updated_at",limit:10){data{id updated_at entity_type{id title number_field{id name} title_field{id name}} values(locale:"'+intl.locale+'"){field{id} string integer field_option{title titles{translation language{locale}}}}}}' +
      '}').then(r => {
      if (r && r.hasOwnProperty('dashboard')) {
        let labels = [];
        let dataset = [];
        r.dashboard.changes.forEach(c => {
          labels.push(c.date);
          dataset.push(c.value);
        });
        r.dashboard.changes = {
          labels: labels,
          datasets: [{
            label: intl.formatMessage({id:"dashboard.changes.dataset.label", defaultMessage:"Changes"}),
            data: dataset,
            borderColor: themeColors.secondary.main,
          }]
        };
        setStats(r.dashboard);
      }
      if (r && r.hasOwnProperty('channels')) {
        setChannels(r.channels.data);
      }
      if (r && r.hasOwnProperty('entities')) {
        setEntities(r.entities.data);
      }
      setIsLoading(false);
    });
  }, []);

  const getEntityFieldValue = (field, values) => {
    const value = values?.find(v => v.field.id === field?.id);
    if (!value) return '';
    if (value.string) return value.string;
    if (value.integer) return value.integer;
    if (value.field_option) {
      const title = value.field_option.titles?.find(t => t.language.locale === intl.locale);
      if (title) return title.translation;
      return value.field_option.title;
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{textAlign:"center"}}><Typography variant="h3">{intl.formatMessage({id:"dashboard.welcome", defaultMessage:"Welcome back {name}, your last login were on {last_login_at}"},
          {
            name: authUser().name,
            last_login_at: authUser().last_login_at
              ? moment(authUser().last_login_at).format(intl.formatMessage({id:"common.datetime.format"}))
              : '',
          })}</Typography>
        </Grid>
        {isLoading && <Grid item xs={12} style={{textAlign:"center"}}>
          <h2>{intl.formatMessage({id:"dashboard.loading", defaultMessage:"Calculating statistics, please wait!"})}</h2>
          <CircularProgress style={{height:64}}/>
        </Grid>}
      </Grid>
      {!isLoading && <Grid container component={Box}><Grid item xs={3}>
        <Card>
          <CardContent style={{textAlign:"center",minHeight:192}}>
            <Link href="/admin/users"><h2 style={{marginBlockEnd:0,marginBlockStart:18}}><People color="secondary" style={{fontSize:48}}/><br/>{
              intl.formatMessage({id:"dashboard.stats.users", defaultMessage:"{users} users"}, stats)
            }</h2></Link>
            <Link href="/admin/roles">{intl.formatMessage({id:"dashboard.stats.roles", defaultMessage:"{roles} roles"}, stats)}</Link>
          </CardContent>
        </Card>
      </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent style={{textAlign:"center",minHeight:192}}>
              <Link href="/data"><h2 style={{marginBlockEnd:0,marginBlockStart:18}}><Apps color="secondary" style={{fontSize:48}}/><br/>{
                intl.formatMessage({id:"dashboard.stats.entities", defaultMessage:"{entities, number} entities"}, stats)
              }</h2></Link>
              <Link href="/data">{intl.formatMessage({id:"dashboard.stats.entity_values", defaultMessage:"{entity_values, number} values"}, stats)}</Link>
              &nbsp;&&nbsp;
              <Link href="/admin/data-types">{intl.formatMessage({id:"dashboard.stats.entity_types", defaultMessage:"{entity_types, number} types"}, stats)}</Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent style={{textAlign:"center",minHeight:192}}>
              <Link href="/admin/fields"><h2 style={{marginBlockEnd:0,marginBlockStart:18}}><EditAttributes color="secondary" style={{fontSize:48}}/><br/>{
                intl.formatMessage({id:"dashboard.stats.fields", defaultMessage:"{fields, number} fields"}, stats)
              }</h2></Link>
              <Link href="/admin/field_groups">{intl.formatMessage({id:"dashboard.stats.field_groups", defaultMessage:"{field_groups, number} groups"}, stats)}</Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardContent style={{textAlign:"center",minHeight:192}}>
              <Link href="/admin/connectors"><h2 style={{marginBlockEnd:0,marginBlockStart:18}}><ImportExport color="secondary" style={{fontSize:48}}/><br/>{
                intl.formatMessage({id:"dashboard.stats.connectors", defaultMessage:"{connectors} connectors"}, stats)
              }</h2></Link>
              <Link href="/admin/channels">{intl.formatMessage({id:"dashboard.stats.channels", defaultMessage:"{channels} channels"}, stats)}</Link>
            </CardContent>
          </Card>
        </Grid>
      </Grid>}
      {!isLoading && <Grid container component={Box} marginTop="2rem">
        <Grid item xs={12} xl={4}>
          <Card>
            <CardHeader
              title={intl.formatMessage({id: "dashboard.changes.title", defaultMessage: "Entities changed the last month"})}
            ></CardHeader>
            <CardContent>
              <Box position="relative" height={285}>
                <Line
                  data={stats.changes}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    layout: {
                      padding: 0,
                    },
                    elements: {
                      point: {
                        radius: 0,
                      },
                    },
                    tooltips: {
                      enabled: true,
                      mode: "index",
                      intersect: false,
                    },
                    scales: {
                      yAxes: [
                        {
                          gridLines: {
                            color: themeColors.gray["500"],
                            zeroLineColor: themeColors.gray["500"],
                          },
                          ticks: {
                            callback: function (value) {
                              if (!(value % 10)) {
                                return value;
                              }
                            },
                          },
                        },
                      ]
                    },
                  }}
                  getDatasetAtEvent={(e) => console.log(e)}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          xl={8}
          component={Box}
          marginBottom="3rem!important"
          classes={{root: classes.gridItemRoot}}
        >
          <Card
            classes={{
              root: classes.cardRoot,
            }}
          >
            <CardHeader
              subheader={
                <Grid
                  container
                  component={Box}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs="auto">
                    <Box
                      component={Typography}
                      variant="h3"
                      marginBottom="0!important"
                    >{intl.formatMessage({
                      id: "dashboard.channels.heading",
                      defaultMessage: "The last 10 executed or changed channels"
                    })}</Box>
                  </Grid>
                  <Grid item xs="auto">
                    <Box
                      justifyContent="flex-end"
                      display="flex"
                      flexWrap="wrap"
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href={'/admin/channels'}
                      >{intl.formatMessage({id: "dashboard.button.see_all", defaultMessage: "See all"})}</Button>
                    </Box>
                  </Grid>
                </Grid>
              }
              classes={{root: classes.cardHeaderRoot}}
            ></CardHeader>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{
                      intl.formatMessage({id: "dashboard.channels.title", defaultMessage: "Title"})
                    }</TableCell>
                    <TableCell align={"right"} width={175}>{
                      intl.formatMessage({id: "dashboard.channels.last_run_at", defaultMessage: "Ran/changed"})
                    }</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {channels.map(row => <TableRow
                    hover
                    key={'channels-' + row.id}
                    style={{cursor: "pointer"}}
                    onClick={() => navigate('/admin/channels/' + row.id)}
                  >
                    <TableCell>{row.title}</TableCell>
                    <TableCell align={"right"}>{
                      moment(row.updated_at).format(intl.formatMessage({id: "common.datetime.format"}))
                    }</TableCell>
                  </TableRow>)}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>}
      {!isLoading && <Grid container component={Box} marginTop="2rem">
        <Grid
          item
          xs={12}
          xl={12}
          component={Box}
          marginBottom="3rem!important"
          classes={{root: classes.gridItemRoot}}
        >
          <Card
            classes={{
              root: classes.cardRoot,
            }}
          >
            <CardHeader
              subheader={
                <Grid
                  container
                  component={Box}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs="auto">
                    <Box
                      component={Typography}
                      variant="h3"
                      marginBottom="0!important"
                    >{intl.formatMessage({
                      id: "dashboard.entities.heading",
                      defaultMessage: "Last changed entities"
                    })}</Box>
                  </Grid>
                  <Grid item xs="auto">
                    <Box
                      justifyContent="flex-end"
                      display="flex"
                      flexWrap="wrap"
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        href={'/data'}
                      >{intl.formatMessage({id: "dashboard.button.see_all", defaultMessage: "See all"})}</Button>
                    </Box>
                  </Grid>
                </Grid>
              }
              classes={{root: classes.cardHeaderRoot}}
            ></CardHeader>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>{
                      intl.formatMessage({id: "dashboard.entities.id", defaultMessage: "ID"})
                    }</TableCell>
                    <TableCell>{
                      intl.formatMessage({id: "dashboard.entities.type", defaultMessage: "Type"})
                    }</TableCell>
                    <TableCell>{
                      intl.formatMessage({id: "dashboard.channels.number_value", defaultMessage: "Number value"})
                    }</TableCell>
                    <TableCell>{
                      intl.formatMessage({id: "dashboard.channels.title_value", defaultMessage: "Title value"})
                    }</TableCell>
                    <TableCell align={"right"} width={175}>{
                      intl.formatMessage({id: "dashboard.channels.updated_at", defaultMessage: "Changed"})
                    }</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entities.map(row => <TableRow
                    hover
                    key={'entities-' + row.id}
                    style={{cursor: "pointer"}}
                    onClick={() => navigate('/data/' + row.entity_type.id + '/entity/' + row.id)}
                  >
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{
                      row.entity_type.title
                    }</TableCell>
                    <TableCell title={row.entity_type.number_field?.name}>{
                      getEntityFieldValue(row.entity_type.number_field, row.values)
                    }</TableCell>
                    <TableCell title={row.entity_type.title_field?.name}>{
                      getEntityFieldValue(row.entity_type.title_field, row.values)
                    }</TableCell>
                    <TableCell align={"right"}>{
                      moment(row.updated_at).format(intl.formatMessage({id: "common.datetime.format"}))
                    }</TableCell>
                  </TableRow>)}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>}
    </>
  );
}

export default Dashboard;
