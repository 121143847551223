import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  LinearProgress,
  ListSubheader,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import {ArrowDownward, ArrowForward, CloudDownload, Delete, SwapHoriz,} from '@material-ui/icons';
import {Autocomplete} from '@material-ui/lab';
import Field from 'components/Fields/Field';
import {authUser, useAuthDispatch} from 'contexts/Auth';
import React, {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {graphQLApi} from 'services/GraphQLApi';
import Checkbox from '@material-ui/core/Checkbox';
import AutocompleteApi from '../../../components/AutocompleteApi/AutocompleteApi';
import {useLocation, useNavigate, useParams} from "react-router-dom";

export default function ChannelEdit() {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  let id = Number(params.id);

  const [channel, setChannel] = useState({
    id: id,
    is_active: false,
    title: "",
    source_connector: '',
    source_language: '',
    destination_connector: '',
    destination_language: '',
    listing: null,
    do_not_delete_values: false,
    only_update_entities: false,
    job_id: null,
    job_message: null,
    progress: null,
    progress_total: null,
    progress_message: null,
  });
  const [isLoading, setIsLoading] = useState(1);
  const [fields, setFields] = useState([]);
  const [connectors, setConnectors] = useState([]);
  // const [languages, setLanguages] = useState([]);
  // const [settings, setSettings] = useState({});
  const [sourceAnalysis, setSourceAnalysis] = useState([]);
  const [destinationAnalysis, setDestinationAnalysis] = useState([]);
  const [entityTypes, setEntityTypes] = useState([]);
  const [listings, setListings] = useState([]);
  const [mappings, setMappings] = useState([]);
  const [validation, setValidation] = useState([]);
  const [channelMappings, setChannelMappings] = useState([]);
  const [showAddFieldDialog, setShowAddFieldDialog] = useState(false);

  const updateMapping = (index, key, value) => {
    // console.log('Updating mapping on mapping[' + index + '].' + key + '=', value);
    let maps = [...mappings];
    // let type = key.test(/^source_.+/) ? 'source' : 'destination';
    maps[index][key] = value;
    setMappings(maps);
  }

  // const updatePrimary = (index, type) => {
  //   setMappings(curMapping => curMapping.map((m, i) => ({...m, [type + '_primary']: i === index})));
  // }
  //
  // const updateParent = (index, type) => {
  //   setMappings(curMapping => curMapping.map((m, i) => ({...m, [type + '_parent']: i === index})));
  // }

  const deleteMapping = (index) => {
    setMappings(mappings.filter((v, k) => k !== index));
  }

  const handleErrors = (errors) => {
    let vs = {};
    if (errors) {
      errors.forEach(e => {
        if (e && e.extensions && e.extensions.validation) {
          vs = {...vs, ...e.extensions.validation};
        }
      });
    }
    setValidation(vs);
  }

  const DEFAULT_LANGUAGE_ID = "0";
  const client = new graphQLApi(useAuthDispatch(), handleErrors);
  const stableClient = useCallback(client, []);
  const queryChannelMappings = 'channel_mappings{id update_on_match ' +
    'source_element source_type source_script source_column source_field{id name type entity_type{title} uses_languages}' +
    'destination_element destination_type destination_script destination_column destination_field{id name type entity_type{title} field_options{id title} uses_languages}' +
    '}';

  const setMappingsFromQuery = (channel_mappings, fields) => {
    setMappings(channel_mappings.map(cm => {
      return {
        ...cm,
        source_field: cm.source_column !== null
          ? fields.find(ff => ff.id === cm.source_column)
          : cm.source_field,
        destination_field: cm.destination_column !== null
          ? fields.find(ff => ff.id === cm.destination_column)
          : cm.destination_field,
      };
    }));
  }

  const buildMappingObject = (type, element, defaultField = null) => {
    return {
      id: null,
      source_field: type === 'destination' ? defaultField : null,
      source_element: type === 'source' ? element.name : '',
      destination_field: type === 'source' ? defaultField : null,
      destination_element: type === 'destination' ? element.name : '',
    };
  }

  const setCommonDataStates = (response) => {
    let conArray = [];
    let s = {};
    if (response.hasOwnProperty('settings')) {
      response.settings.data.forEach(st => {
        s[st.key] = st.data;
      });
      // setSettings(s);
    }
    let fetchedFields = [];
    if (response.hasOwnProperty('listings')) {
      setListings(response.listings.data);
    }
    if (response.hasOwnProperty('fields')) {
      fetchedFields = [
        {
          id: "variant_fields",
          name: intl.formatMessage({id: "channels.edit.field.variant_fields", defaultMessage: "Variant fields"}),
          type: "variant_fields",
          uses_languages: false,
        },
        {
          id: "variants",
          name: intl.formatMessage({id: "channels.edit.field.variants", defaultMessage: "Variants"}),
          type: "variants",
          uses_languages: false,
        },
        ...response.fields.data
      ];
    }
    if (response.hasOwnProperty('entityTypes')) {
      setEntityTypes(response.entityTypes.data);
      response.entityTypes.data.forEach(e =>
        fetchedFields.unshift({
          id: "_create_" + e.id,
          name: intl.formatMessage({
            id: "channels.label.create_new_field",
            defaultMessage: "> Create field in {entity_type} <"
          }, {entity_type: e.title}),
          type: "-",
          uses_languages: null,
        })
      );
    }
    setFields(fetchedFields);
    let languages = [];
    if (response.hasOwnProperty('languages')) {
      languages = response.languages.data.map(v => ({type: "language", id: v.id, name: v.name}));
      languages.unshift({type: "language", id: DEFAULT_LANGUAGE_ID, name: s.language});
    }
    // setLanguages(languages);
    if (response.hasOwnProperty('connectors')) {
      conArray = [{
        id: null,
        title: "OpenDIMS",
        driver_needs_type: false,
        driver_can_import: true,
        driver_can_export: true,
        driver_can_update: true,
        languages: languages.map(l => ({name: l.name, external_id: l.id})),
      }, ...response.connectors.data];
      setConnectors(conArray);
    }
    return {connectorsArray: conArray, languages: languages, fetchedFields: fetchedFields};
  }
  const fieldFields = 'id name type uses_languages field_options{id title} entity_type{title}';

  const connectorsQuery = 'id title driver languages{external_id name} driver_needs_type driver_can_update driver_can_import driver_can_export settings';

  useEffect(() => {
    const commonQueries =
      'entityTypes(sorting:"id"){data{id title}}' +
      'listings(sorting:"title",filter:{user_id:null}){data{id title entity_type{id}}}' +
      'connectors{data{id title driver driver_needs_type driver_can_update driver_can_import driver_can_export settings}}' +
      'languages{data{id name}}' +
      'settings{data{ id key data }}';
    if (id) {
      stableClient.query('{' +
        'channels(filter:{id:' + id + '}) {data{' +
        '  source_connector{' + connectorsQuery + '} source_language ' +
        '  destination_connector{' + connectorsQuery + '} destination_language ' +
        '  id entity_type{id title} listing{id title} title progress progress_total progress_message job_id job_message do_not_delete_values only_update_entities source_analysis destination_analysis only_changed_since_last_start' +
        '  ' + queryChannelMappings +
        '}}' +
        'fields{data{ ' + fieldFields + ' }}' +
        commonQueries +
        '}').then(response => {
        if (response) {
          let data = response.channels?.data?.shift();
          if (data) {
            let i = null;
            if (data.source_connector) {
              i = response.connectors.data.findIndex(c => c.id === data.source_connector.id);
              if (i !== -1) response.connectors.data.splice(i, 1, data.source_connector);
            }
            if (data.destination_connector) {
              i = response.connectors.data.findIndex(c => c.id === data.destination_connector.id);
              if (i !== -1) response.connectors.data.splice(i, 1, data.destination_connector);
            }
          }
          let {connectorsArray, _languages, fetchedFields} = setCommonDataStates(response);
          if (data) {
            let analysis = null;
            if (data.hasOwnProperty('source_analysis') && data.source_analysis) {
              analysis = JSON.parse(data.source_analysis);
              if (analysis.hasOwnProperty('error')) {
                console.log(analysis.error);
                validation['source_connector_id'] = [analysis.error]
                // return;
              }
              setSourceAnalysis(analysis);
            }
            setChannel(cur => {
              data.listing_id = data.listing ? data.listing.id : "";
              data.entity_type_id = data.entity_type ? data.entity_type.id : "";
              if (!data.source_connector) {
                data.source_connector = connectorsArray[0];
                setSrcLanguages(connectorsArray[0].languages);
              } else {
                setSrcLanguages(data.source_connector.languages);
              }
              if (!data.destination_connector) {
                data.destination_connector = connectorsArray[0];
                setDestLanguages(connectorsArray[0].languages);
              } else {
                setDestLanguages(data.destination_connector.languages);
              }
              cur = {...cur, ...data};
              return cur;
            });
            if (data.job_id !== null) {
              startProgressChecker();
            }

            if (data.hasOwnProperty('channel_mappings') && data.channel_mappings.length) {
              setChannelMappings(data.channel_mappings);
              setMappingsFromQuery(data.channel_mappings, fetchedFields);
            } else if (analysis && analysis.hasOwnProperty('elements')) {
              setMappings(analysis.elements.map(fe => {
                return buildMappingObject('source', fe, fetchedFields.find(f => f.id === '_create_' + data.entity_type_id));
              }));
            }

            if (data.hasOwnProperty('destination_analysis') && data.destination_analysis) {
              analysis = JSON.parse(data.destination_analysis);
              setDestinationAnalysis(analysis);
            }
          }
        }
        setIsLoading(false);
      });
    } else {
      stableClient.query('{' +
        commonQueries +
        '}').then(response => {
        if (response) {
          setCommonDataStates(response);
        }
        setIsLoading(false);
      });
    }
  }, [stableClient, id]);

  const handleSave = (event, start_job = false) => {
    setIsLoading(true);
    setValidation([]);
    if (channel.id) {
      client.mutate('($id:ID!, $listing_id:ID, $entity_type_id:ID, $title:String, $is_active:Boolean, $do_not_delete_values:Boolean $only_update_entities:Boolean $source_language:String, $only_changed_since_last_start:Boolean $destination_connector_id:ID, $destination_language:String, $channel_mappings:[ChannelMappingInput], $start_job:Boolean)' +
        '{channelUpdate(id:$id, is_active:$is_active, listing_id:$listing_id, entity_type_id:$entity_type_id, title:$title,source_language:$source_language, only_changed_since_last_start:$only_changed_since_last_start, destination_connector_id:$destination_connector_id, destination_language:$destination_language, do_not_delete_values:$do_not_delete_values, only_update_entities:$only_update_entities channel_mappings:$channel_mappings,start_job:$start_job) {id ' + queryChannelMappings + '}}', {
        id: id,
        title: channel.title,
        is_active: true,
        do_not_delete_values: channel.do_not_delete_values,
        only_update_entities: channel.only_update_entities,
        listing_id: channel.listing_id,
        entity_type_id: channel.entity_type_id,
        source_language: channel.source_language,
        only_changed_since_last_start: channel.only_changed_since_last_start,
        destination_connector_id: channel.destination_connector ? channel.destination_connector.id : null,
        destination_language: channel.destination_language,
        start_job: start_job,
        channel_mappings: mappings.map(cm => ({
          id: cm.id,
          channel_id: channel.id,
          source_element: cm.source_element,
          source_type: cm.source_type?.id ? cm.source_type.id : cm.source_type,
          source_script: cm.source_script,
          source_field_id: cm.source_field && parseInt(cm.source_field.id) > 0 ? cm.source_field.id : null,
          source_column: cm.source_field && isNaN(parseInt(cm.source_field.id)) ? cm.source_field.id : null,
          destination_element: cm.destination_element,
          destination_type: cm.destination_type,
          destination_script: cm.destination_script,
          destination_field_id: cm.destination_field && parseInt(cm.destination_field.id) > 0 ? cm.destination_field.id : null,
          destination_column: cm.destination_field && isNaN(parseInt(cm.destination_field.id)) ? cm.destination_field.id : null,
          update_on_match: cm.update_on_match
        })),
      }).then(response => {
        if (response && response.hasOwnProperty('channelUpdate')) {
          let data = response.channelUpdate;
          setChannel(cur => {
            if (start_job) {
              startProgressChecker();
              return {
                ...cur, ...data,
                job_id: 0,
                progress: 0,
                progress_total: 0,
                progress_message: 'Dispatching job to queue'
              };
            }
            return {...cur, ...data};
          });
          let analysis = {};
          if (data.hasOwnProperty('source_analysis')) {
            analysis = JSON.parse(data.source_analysis);
          }
          if (data.hasOwnProperty('channel_mappings') && data.channel_mappings.length) {
            setChannelMappings(data.channel_mappings);
            setMappingsFromQuery(data.channel_mappings, fields, analysis);
          } else if (analysis && analysis.hasOwnProperty('elements')) {
            setMappings(analysis.elements.map(fe => {
              return buildMappingObject('source', fe);
            }));
          }
        }
        setIsLoading(false);
      });
    } else {
      client.mutate('($listing_id:ID, $entity_type_id:ID, $title:String, $is_active:Boolean, $do_not_delete_values:Boolean $only_update_entities:Boolean, $source_connector_id:ID, $source_language:String, $destination_connector_id:ID, $destination_language:String)' +
        '{channelCreate(is_active:$is_active, listing_id:$listing_id, entity_type_id:$entity_type_id, title:$title, do_not_delete_values:$do_not_delete_values, only_update_entities:$only_update_entities, source_connector_id:$source_connector_id, source_language:$source_language, destination_connector_id:$destination_connector_id, destination_language:$destination_language){id}}', {
        is_active: true,
        title: channel.title,
        listing_id: channel.listing_id,
        entity_type_id: channel.entity_type_id,
        do_not_delete_values: channel.do_not_delete_values,
        only_update_entities: channel.only_update_entities,
        source_connector_id: channel.source_connector ? channel.source_connector.id : null,
        source_language: channel.source_language,
        destination_connector_id: channel.destination_connector ? channel.destination_connector.id : null,
        destination_language: channel.destination_language,
      }).then(response => {
        if (response && response.hasOwnProperty('channelCreate')) {
          navigate(location.pathname.replace('create', response.channelCreate.id), {replace: true});
        } else {
          setIsLoading(false);
        }
      });
    }
  }

  let intervalProgressTimer = null;
  const startProgressChecker = () => {
    intervalProgressTimer = window.setInterval(() => {
      client.query('{channels(filter:{id:' + id + '}) {data{progress progress_total progress_message job_id job_message}}}').then(r => {
        if (r && r.channels) {
          setChannel(cur => {
            return {...cur, ...r.channels.data[0]};
          });
          if (r.channels.data[0].job_id === null) {
            window.clearInterval(intervalProgressTimer);
          }
        }
      });
    }, 2500);
  }

  const isSourceConnected = channel.source_connector?.id > 0;
  const isDestinationConnected = channel.destination_connector?.id > 0;

  const [srcLanguages, setSrcLanguages] = useState([]);
  const [destLanguages, setDestLanguages] = useState([]);
  const [isLoadingConnLang, setIsLoadingConnLang] = useState('');
  const fetchConnectorLanguages = (connector, destination = false) => {
    if (!connector?.id) {
      if (destination) setDestLanguages(connectors[0].languages);
      else setSrcLanguages(connectors[0].languages);
      setIsLoadingConnLang('');
    } else {
      setIsLoadingConnLang(connector.id);
      client.query('{connectors(filter:{id:' + connector.id + '}){data{' + connectorsQuery + '}}}').then(r => {
        if (r?.connectors?.data) {
          if (destination) setDestLanguages(r.connectors.data[0].languages);
          else setSrcLanguages(r.connectors.data[0].languages);
        }
        setIsLoadingConnLang('');
      });
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            color="primary"
            avatar={<SwapHoriz/>}
            title={intl.formatMessage({id: "channels.edit.heading", defaultMessage: "Channels edit"})}/>
          <form>
            <CardContent>
              <Grid container spacing={2} alignItems={"flex-start"}>
                <Grid item md={5} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={intl.formatMessage({id: "channels.edit.label.title", defaultMessage: "Title"})}
                        value={channel.title}
                        onChange={e => setChannel(cur => {
                          return {...cur, title: e.target.value};
                        })}
                        error={!!validation.title}
                        helperText={validation.title ? validation.title.join(" ") : ""}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        select
                        fullWidth
                        error={!!validation.entity_type_id}
                        label={intl.formatMessage(
                          {id: "channels.edit.label.entity_type", defaultMessage: "Entity type"}
                        )}
                        id={"entity_type"}
                        name={"entity_type"}
                        value={channel.entity_type_id ? channel.entity_type_id : ""}
                        onChange={(e) => setChannel(cur => {
                          return {...cur, entity_type_id: e.target.value};
                        })}
                        helperText={!!validation.entity_type_id && validation.entity_type_id.join(" ")}
                      >{entityTypes.map(c =>
                        <MenuItem key={"entity_type_" + c.id} value={c.id}>{c.title}</MenuItem>
                      )}</TextField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={3} xs={5}>
                  <Grid container spacing={2} alignItems={"flex-start"}>
                    <Grid item xs={12}>
                      <FormLabel style={{fontWeight: "bold"}}>{intl.formatMessage({
                        id: "channels.edit.label.source",
                        defaultMessage: "Source"
                      })}</FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        select
                        fullWidth
                        error={!!validation.source_connector_id}
                        label={intl.formatMessage({
                          id: "channels.edit.label.connector",
                          defaultMessage: "Connector"
                        })}
                        id={"source-connector"}
                        disabled={!!id}
                        value={channel.source_connector}
                        onChange={(e) => setChannel(cur => {
                          fetchConnectorLanguages(e.target.value, false);
                          return {...cur, source_connector: e.target.value};
                        })}
                        helperText={!!validation.source_connector_id && validation.source_connector_id.join(' ')}
                      >{connectors.filter(x => x.driver_can_import === true).filter(c => (!channel.source_connector || c.driver === channel.source_connector.driver)).map(c =>
                        <MenuItem key={"source_" + c.id} value={c}>{c.title}</MenuItem>
                      )}</TextField>
                    </Grid>
                    <Grid item xs={12}>
                      {(channel.source_connector && isLoadingConnLang === channel.source_connector?.id)
                        ? <CircularProgress size={24}/>
                        : <TextField
                        select
                        fullWidth
                        error={!!validation.source_language_id}
                        label={intl.formatMessage({
                          id: "channels.edit.label.language",
                          defaultMessage: "Language"
                        })}
                        id={"source-language"}
                        disabled={!channel.source_connector}
                        value={channel.source_language}
                        onChange={e => setChannel(cur => ({...cur, source_language: e.target.value}))}
                        helperText={!!validation.source_language_id && validation.source_language_id.join(' ')}
                        >{srcLanguages.map(l =>
                          <MenuItem key={"source_language_" + l.external_id} value={l.external_id}>{l.name}</MenuItem>
                        ) || null}</TextField>}
                    </Grid>
                    {channel.source_connector?.id === null && <>
                      <Grid item xs={12}>
                        <TextField
                          select
                          error={!!validation.listing_id}
                          fullWidth
                          label={intl.formatMessage({
                            id: 'channels.edit.label.listing',
                            defaultMessage: 'List',
                          })}
                          id={'listing'}
                          name={'listing'}
                          value={channel.listing_id ? channel.listing_id : ''}
                          onChange={(e) => setChannel(cur => {
                            return {...cur, listing_id: e.target.value};
                          })}
                          helperText={!!validation.listing_id &&
                            validation.listing_id.join(' ')}
                        >{listings.filter(l => {
                          if (channel.entity_type_id) {
                            return (l.entity_type && l.entity_type.id ===
                              channel.entity_type_id);
                          }
                          return true;
                        }).map(c =>
                          <MenuItem key={'listing_' + c.id}
                                    value={c.id}>{c.title}</MenuItem>,
                        )}</TextField>
                      </Grid>
                      <Grid item container xs={12}>
                        <FormControlLabel
                          control={<Checkbox color="primary"
                                             checked={channel.only_changed_since_last_start}
                                             onChange={(e) => setChannel(
                                               cur => {
                                                 return {
                                                   ...cur,
                                                   only_changed_since_last_start: e.target.checked,
                                                 };
                                               })}
                          />}
                          label={intl.formatMessage({
                            id: 'channels.edit.entities_updated_checkbox',
                            defaultMessage: 'Update since last run',
                          })}
                          labelPlacement="end"
                        />
                      </Grid>
                    </>
                    }
                  </Grid>
                </Grid>
                <Grid item md={1} xs={2} style={{textAlign: "center", verticalAlign: "middle", marginTop: 54}}>
                  <ArrowForward/>
                </Grid>
                <Grid item md={3} xs={5}>
                  <Grid container spacing={2} alignItems={"flex-end"}>
                    <Grid item xs={12}>
                      <FormLabel style={{fontWeight: "bold"}}>{intl.formatMessage({
                        id: "channels.edit.label.destination",
                        defaultMessage: "Destination"
                      })}</FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        select
                        error={!!validation.destination_connector_id}
                        helperText={!!validation.destination_connector_id && validation.destination_connector_id.join(' ')}
                        label={intl.formatMessage({
                          id: "channels.edit.label.connector",
                          defaultMessage: "Connector"
                        })}
                        id={"destination-connector"}
                        // disabled={!!id}
                        value={channel.destination_connector}
                        onChange={(e) => setChannel(cur => {
                          fetchConnectorLanguages(e.target.value, true);
                          return {...cur, destination_connector: e.target.value};
                        })}
                      >{connectors.filter(c => c.driver_can_export === true && (!channel.destination_connector || c.driver === channel.destination_connector.driver)).map(c =>
                        <MenuItem key={"destination_" + c.id} value={c}>{c.title}</MenuItem>
                      )}</TextField>
                    </Grid>
                    <Grid item xs={12}>
                      {(channel.destination_connector && isLoadingConnLang === channel.destination_connector.id)
                        ? <CircularProgress size={24}/>
                        : <TextField
                        select
                        error={!!validation.destination_language_id}
                        label={intl.formatMessage({
                          id: "channels.edit.label.language",
                          defaultMessage: "Language"
                        })}
                        fullWidth
                        labelId={"destination-language-label"}
                        id={"destination-language"}
                        disabled={!channel.destination_connector}
                        value={channel.destination_language}
                        onChange={e => setChannel(cur => ({...cur, destination_language: e.target.value}))}
                        helperText={!!validation.destination_language_id && validation.destination_language_id.join(' ')}
                        >{destLanguages.map(l =>
                          <MenuItem key={"destination_language_" + l.external_id}
                                    value={l.external_id}>{l.name}</MenuItem>
                        )}</TextField>}
                    </Grid>
                    {(channel.destination_connector && channel.destination_connector.id === null) && <Grid item xs={12}>
                      <FormControlLabel
                        control={<Checkbox color="primary"
                                           checked={channel.do_not_delete_values}
                                           onChange={(e) => setChannel(cur => {
                                             return {...cur, do_not_delete_values: e.target.checked};
                                           })}
                        />}
                        label={intl.formatMessage({
                          id: "channels.edit.do_not_delete_checkbox",
                          defaultMessage: "Do not delete values"
                        })
                        }
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        control={<Checkbox color="primary"
                                           checked={channel.only_update_entities}
                                           onChange={(e) => setChannel(cur => {
                                             return {...cur, only_update_entities: e.target.checked};
                                           })}
                        />}
                        label={intl.formatMessage({
                          id: "channels.edit.only_update_checkbox",
                          defaultMessage: "Only update entities"
                        })
                        }
                        labelPlacement="end"
                      />
                    </Grid>}
                    {(channel.destination_connector && channel.destination_connector.settings?.filename) &&
                      <Grid item xs={12}>
                        <IconButton
                          href={'https://cdn.opendims.com/' + authUser().site.schema + '/exports/' + channel.destination_connector.settings.filename}
                          target="_blank">
                          <CloudDownload style={{height: 20, width: 20}}/></IconButton>
                        {channel.destination_connector.settings.filename}
                      </Grid>}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            {!id ? '' :
              <CardActions style={{alignContent: "flex-start", alignItems: "flex-start", flexDirection: "column"}}>
                <TableContainer style={{display: "flex"}}>
                  <Table size="small">
                    <TableHead><TableRow>
                      <TableCell>{
                        isSourceConnected
                          ? intl.formatMessage({
                            id: "channels.mapping.column.source_element",
                            defaultMessage: "Source element"
                          })
                          : intl.formatMessage({
                            id: "channels.mapping.column.source_field",
                            defaultMessage: "Source field"
                          })
                      }</TableCell>
                      {isSourceConnected && channel.source_connector && channel.source_connector.driver_needs_type ?
                        <TableCell>{intl.formatMessage({
                          id: "channels.mapping.column.source_type",
                          defaultMessage: "Source type"
                        })}</TableCell> : ''}
                      <TableCell width={35}>&nbsp;</TableCell>
                      <TableCell>{
                        isDestinationConnected
                          ? intl.formatMessage({
                            id: "channels.mapping.column.destination_element",
                            defaultMessage: "Destination element"
                          })
                          : intl.formatMessage({
                            id: "channels.mapping.column.destination_field",
                            defaultMessage: "Destination field"
                          })
                      }</TableCell>
                      {isDestinationConnected && channel.destination_connector && channel.destination_connector.driver_needs_type ?
                        <TableCell>{intl.formatMessage({
                          id: "channels.mapping.column.destination_type",
                          defaultMessage: "Destination type"
                        })}</TableCell> : ''}
                      {channel.destination_connector && channel.destination_connector.driver_can_update ?
                        <TableCell>{intl.formatMessage({
                          id: "channels.mapping.column.update_on_match",
                          defaultMessage: "Update on match"
                        })}</TableCell> :
                        ''}
                      <TableCell width={35}>&nbsp;</TableCell>
                    </TableRow></TableHead>
                    <TableBody>{mappings.map((mapping, mapIndex) =>
                      <TableRow key={"mapping-" + mapIndex} hover>
                        <TableCell style={{border: "none"}}>{isSourceConnected
                          ? <TextField
                            select
                            id={"source_element_select_" + (mapIndex + 1)}
                            fullWidth
                            value={mapping.source_element ? mapping.source_element : ''}
                            onChange={event => updateMapping(mapIndex, 'source_element', event.target.value)}
                            name="source_element"
                          >{(sourceAnalysis && sourceAnalysis.elements) ? sourceAnalysis.elements.map((e, i) =>
                            <MenuItem key={"mapping-" + mapIndex + "-source_element-" + i} value={e.name}>
                              {e.name}
                            </MenuItem>
                          ) : []}
                            {mapping.destination_field?.field_options?.length ?
                              [
                                <ListSubheader>{mapping.destination_field.name}</ListSubheader>,
                                ...mapping.destination_field?.field_options.map((o, j) =>
                                  <MenuItem key={"mapping-" + mapIndex + "-source_element-" + j}
                                            value={"##opendims::field_option_" + o.id + "##"}>{o.title}</MenuItem>)
                              ]
                              : []
                            }
                          </TextField>
                          : <Autocomplete
                            name="source_field"
                            fullWidth
                            id={"field_select_" + (mapIndex + 1)}
                            value={fields.length ? mapping.source_field : null}
                            onChange={(event, value) => {
                              updateMapping(mapIndex, 'source_field', value);
                              if (isDestinationConnected && value)
                                updateMapping(mapIndex, 'destination_element', value.name)
                            }}
                            options={fields.filter(f => {
                              console.log(f);
                              if (f.id.indexOf("_create") !== -1) return false;
                              if (!mapping.source_elementType) return true;
                              if (mapping.source_elementCreatable) return true;
                              let type = mapping.source_elementType.split('|');
                              return !!type.find(t => t === f.type && (mapping.source_elementLanguage === null || mapping.source_elementLanguage === f.uses_languages));
                            })}
                            getOptionLabel={option => option ? ((option.entity_type ? option.entity_type.title + ': ' : '') + option.name) : ""}
                            getOptionSelected={(option, value) => (value !== "" && option.id === value.id)}
                            renderOption={(option) =>
                              <React.Fragment>{(parseInt(option.id) > 0 ? ((option.entity_type ? option.entity_type.title + ': ' : '') + option.name) :
                                <em>{option.name}</em>)}</React.Fragment>}
                            renderInput={(params) => <TextField
                              {...params}
                              style={{fontStyle: (mapping.source_field && parseInt(mapping.source_field.id) > 0) ? "normal" : "italic"}}
                            />}
                          />
                        }{mapping.source_field?.type.match(/^entities_(\d+)$/) &&
                          <AutocompleteApi
                            query="listings"
                            filter={"entity_type_id:" + mapping.source_field.type.match(/^entities_(\d+)$/)[1]}
                            label="Filter by list"
                            titleField="title"
                            selectedValue={mapping.source_type}
                            onChange={(_event, value) => {
                              updateMapping(mapIndex, 'source_type', value);
                            }}
                          />}</TableCell>
                        {(isSourceConnected && channel.source_connector && channel.source_connector.driver_needs_type) ?
                          <TableCell style={{border: "none"}}>
                            <TextField
                              select
                              fullWidth
                              id={"source_type_select_" + (mapIndex + 1)}
                              value={mapping.source_type ? mapping.source_type : 'value'}
                              onChange={event => updateMapping(mapIndex, 'source_type', event.target.value)}
                              name="source_type"
                            >
                              <MenuItem value="value">{intl.formatMessage({
                                id: "channels.mapping.column.simple_value",
                                defaultMessage: "Single value"
                              })}</MenuItem>
                              <MenuItem value="options">{intl.formatMessage({
                                id: "channels.mapping.column.collection_values",
                                defaultMessage: "Collection of values"
                              })}</MenuItem>
                              <MenuItem value="script">{intl.formatMessage({
                                id: "channels.mapping.column.script",
                                defaultMessage: "Custom script or value"
                              })}</MenuItem>
                              <MenuItem value="ignored">{intl.formatMessage({
                                id: "channels.mapping.column.ignored",
                                defaultMessage: "Value for scripts, ignored in output"
                              })}</MenuItem>
                              {entityTypes.map((f, k) =>
                                <MenuItem key={"source_types_entities_" + k} value={"entity_" + f.id}>
                                  {intl.formatMessage({
                                    id: "channels.mapping.column.entity_value",
                                    defaultMessage: "{entity_type} value"
                                  }, {entity_type: f.title})}
                                </MenuItem>
                              )}
                              {fields.filter(f => RegExp(/^[a-z]/).test(f.id)).map((f, k) =>
                                <MenuItem key={"source_types_" + k} value={f.id}>{f.name}</MenuItem>
                              )}
                            </TextField>
                            {mapping.source_type === "script" && <div>
                              <TextField
                                fullWidth
                                multiline
                                inputProps={{style: {fontFamily: "Courier"}}}
                                label={intl.formatMessage({
                                  id: "channels.mapping.label.script",
                                  defaultMessage: "Custom script"
                                })}
                                value={mapping.source_script ? mapping.source_script : ''}
                                onChange={event => updateMapping(mapIndex, 'source_script', event.target.value)}
                              />
                            </div>}
                          </TableCell> : ''}
                        <TableCell align="center" style={{border: "none"}}>
                          <ArrowForward/>
                        </TableCell>
                        <TableCell style={{border: "none"}}>{isDestinationConnected
                          ? <TextField
                            id={"destination_element_select_" + (mapIndex + 1)}
                            fullWidth
                            value={mapping.destination_element ? mapping.destination_element : ''}
                            onChange={event => updateMapping(mapIndex, 'destination_element', event.target.value)}
                            select={destinationAnalysis && destinationAnalysis.elements?.length > 1 && mapping.destination_type !== "ignored"}
                          >
                            {(destinationAnalysis && destinationAnalysis.elements) ? destinationAnalysis.elements.map((e, i) =>
                              <MenuItem key={"mapping-" + mapIndex + "-destination_element-" + i} value={e.name}>
                                {e.name}
                              </MenuItem>
                            ) : []}
                          </TextField>
                          : <Autocomplete
                            disabled={mapping.source_type === 'ignored'}
                            name="destination_field"
                            fullWidth
                            id={"destination_field_select_" + (mapIndex + 1)}
                            value={fields.length ? mapping.destination_field : null}
                            onChange={(event, value) => updateMapping(mapIndex, 'destination_field', value)}
                            options={fields.filter(f => {
                              if (!mapping.destination_elementType) return true;
                              if (mapping.destination_elementCreatable && f.id.indexOf("_create") !== -1) return true;
                              let type = mapping.destination_elementType.split('|');
                              return !!type.find(t => t === f.type && (mapping.destination_elementLanguage === null || mapping.destination_elementLanguage === f.uses_languages));
                            })}
                            getOptionLabel={option => option ? ((option.entity_type ? option.entity_type.title + ': ' : '') + option.name) : ""}
                            getOptionSelected={(option, value) => (value !== "" && option.id === value.id)}
                            renderOption={(option) =>
                              <React.Fragment>{(parseInt(option.id) > 0 ? ((option.entity_type ? option.entity_type.title + ': ' : '') + option.name) :
                                <em>{option.name}</em>)}</React.Fragment>}
                            renderInput={(params) => <TextField
                              {...params}
                              style={{fontStyle: (mapping.destination_field && parseInt(mapping.destination_field.id) > 0) ? "normal" : "italic"}}
                            />}
                          />
                        }</TableCell>
                        {(isDestinationConnected && channel.destination_connector && channel.destination_connector.driver_needs_type) ?
                          <TableCell style={{border: "none"}}>
                            <TextField
                              select
                              fullWidth
                              id={"destination_type_select_" + (mapIndex + 1)}
                              value={mapping.destination_type ? mapping.destination_type : 'value'}
                              onChange={event => updateMapping(mapIndex, 'destination_type', event.target.value)}
                              name="destination_type"
                            >
                              <MenuItem value="value">{intl.formatMessage({
                                id: "channels.mapping.column.simple_value",
                                defaultMessage: "Value from channel entity type"
                              })}</MenuItem>
                              <MenuItem value="options">{intl.formatMessage({
                                id: "channels.mapping.column.collection_values",
                                defaultMessage: "Collection of values"
                              })}</MenuItem>
                              <MenuItem value="script">{intl.formatMessage({
                                id: "channels.mapping.column.script",
                                defaultMessage: "Custom script or value"
                              })}</MenuItem>
                              <MenuItem value="ignored">{intl.formatMessage({
                                id: "channels.mapping.column.ignored",
                                defaultMessage: "Value for scripts, ignored in output"
                              })}</MenuItem>
                              {entityTypes.map((f, k) =>
                                <MenuItem key={"destination_types_entities_" + k} value={"entity_" + f.id}>
                                  {intl.formatMessage({
                                    id: "channels.mapping.column.entity_value",
                                    defaultMessage: "{entity_type} value"
                                  }, {entity_type: f.title})}
                                </MenuItem>
                              )}
                              {fields.filter(f => RegExp(/^[a-z]/).test(f.id)).map(f => <MenuItem
                                value={f.id}>{f.name}</MenuItem>)}
                            </TextField>
                            {mapping.destination_type === "script" && <div>
                              <TextField
                                fullWidth
                                multiline
                                inputProps={{style: {fontFamily: "Courier"}}}
                                label={intl.formatMessage({
                                  id: "channels.mapping.label.script",
                                  defaultMessage: "Custom script"
                                })}
                                value={mapping.destination_script ? mapping.destination_script : ''}
                                onChange={event => updateMapping(mapIndex, 'destination_script', event.target.value)}
                              />
                            </div>}
                          </TableCell> : ''}
                        {(channel.destination_connector && channel.destination_connector.driver_can_update) ?
                          <TableCell align="center" style={{border: "none"}}>
                            <FormGroup>
                              <FormControlLabel
                                disabled={mapping.source_type === 'ignored'}
                                label={""}
                                id={"destination_update_on_match_" + (mapIndex + 1)}
                                control={<Switch
                                  checked={mapping.update_on_match}
                                  onChange={(e) => updateMapping(mapIndex, 'update_on_match', e.target.checked)}
                                  name={"destination_update_on_match_switch_" + (mapIndex + 1)}
                                  color="primary"
                                />}
                                style={{margin: 0}}
                              />
                            </FormGroup>
                          </TableCell> : ''}
                        <TableCell align="right" style={{border: "none"}}>
                          <IconButton size="small" onClick={() => deleteMapping(mapIndex)}><Delete/></IconButton>
                        </TableCell>
                      </TableRow>)}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardActions>}
          </form>
          <CardActions style={{alignContent: "flex-end"}}>
            <Button
              variant="contained"
              color="secondary"
              style={{flexShrink: 0}}
              onClick={() => navigate(-1)}
            >
              {intl.formatMessage({id: "common.button.back"})}
            </Button>
            {channel.job_id === null ?
              <Box marginLeft={"auto"} flexDirection="row" display="flex" flexGrow={1}>
                <Box flexGrow={1} display="inline-flex">{
                  channel.job_message
                    ? intl.formatMessage({
                        id: "channels.label.last_job_message",
                        defaultMessage: "Message from last job execution: {message}"
                      },
                      {message: channel.job_message})
                    : ''}</Box>
                {!id ? '' : <Box display="inline-flex">
                  <Button style={{marginRight: 10}} onClick={() => setShowAddFieldDialog(true)}>
                    {intl.formatMessage({id: "channels.button.add_entity_field", defaultMessage: "Add product field"})}
                  </Button>
                  <Button style={{marginRight: 10}} onClick={() => {
                    setMappings([...mappings, buildMappingObject(null)]);
                  }}>
                    {intl.formatMessage({id: "channels.button.add_mapping", defaultMessage: "Add mapping"})}
                  </Button>
                </Box>}
                {channelMappings.length === 0 ? '' :
                  <Button
                    startIcon={<ArrowDownward/>}
                    variant={"contained"}
                    color={"secondary"}
                    style={{marginRight: 10, display: "inline-flex"}}
                    onClick={(e) => handleSave(e, true)}
                  >
                    {intl.formatMessage({id: "channels.button.start_job", defaultMessage: "Start job"})}
                  </Button>}
                {isLoading > 0 ? <CircularProgress style={{height: 43, width: 43}}/> :
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                  >
                    {id
                      ? intl.formatMessage({id: "common.button.save", defaultMessage: "Save"})
                      : intl.formatMessage({
                        id: "channels.button.save_and_analyze",
                        defaultMessage: "Save and analyze mapping"
                      })}
                  </Button>}
              </Box>
              :
              <Box marginLeft="auto" width="100%" display="flex">
                <Box marginLeft="auto" flexGrow={1} display="inline-flex" flexDirection="column" alignContent="center"
                     alignItems="center">
                  <Box display="inline-flex">{channel.progress_message} - {
                    intl.formatMessage({id: "common.progress.heading", defaultMessage: "Progress {count} of {total}"}, {
                      count: channel.progress,
                      total: channel.progress_total,
                    })}</Box>
                  <LinearProgress variant={"determinate"} color={"primary"}
                                  value={(channel.progress / channel.progress_total * 100)} style={{width: "100%"}}/>
                </Box>
                <Button
                  variant={"contained"}
                  color={"secondary"}
                  style={{marginLeft: 10, display: "inline-flex"}}
                  onClick={() => {
                    window.clearInterval(intervalProgressTimer);
                    client.mutate('{channelUpdate(id:' + id + ',cancel_job:true) {progress progress_total progress_message job_message}}').then(r => {
                      if (r && r.hasOwnProperty('channelUpdate')) {
                        setChannel(cur => {
                          return {...cur, ...r.channelUpdate, job_id: null, job_message: 'Cancelling job...'};
                        });
                      }
                    });
                  }}
                >
                  {intl.formatMessage({id: "channels.button.cancel_job", defaultMessage: "Cancel job"})}
                </Button>
              </Box>
            }
          </CardActions>
        </Card>
        <Dialog open={showAddFieldDialog} maxWidth={"md"}>
          <DialogContent>
            <Field
              returnedFields={fieldFields}
              onSaved={field => {
                setFields([...fields, field]);
                setShowAddFieldDialog(false);
              }}
              buttons={[
                {label: intl.formatMessage({id: "common.button.cancel"}), onClick: () => setShowAddFieldDialog(null)}
              ]}
            />
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
}
