import React, {createContext, useContext, useEffect, useState,} from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography
} from "@material-ui/core";
import EntityList from "./EntityList";
import {Ballot, Clear} from "@material-ui/icons";
import {authUser, useAuthDispatch} from "../../contexts/Auth";
import {getLayouts, rolePriorities} from "../../config";
import Sidebar from "../../components/Sidebar/Sidebar";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import ActionBar from "../../components/ActionBar";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import themeColors from "../../assets/theme/colors";
import RenderEntityValue from "./components/RenderEntityValue";
import ActionMenu from "./components/ActionMenu";
import {LanguageContext} from "../../contexts/Layouts";
import LanguageSelect from "./components/LanguageSelect";
import {graphQLApi} from "../../services/GraphQLApi";
import LayoutTab from "./components/LayoutTab";
import {getEntityValueColumnFromField, getEntityValueFromField} from "../../variables/fields";
import moment from "moment";
import RevisionsListDialog from "../../components/Dialogs/RevisionsListDialog";
import theme from "../../assets/theme/theme";
import ExportLabel from "../../components/Dialogs/ExportLabel";
import SelectPrintoutDialog from "./components/SelectPrintoutDialog";
import {getFieldLabel} from "./components/FieldTypes/Field";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}


const queryValueFields = 'id language_id field_id string text bool integer decimal date datetime related_entity_id' +
  ' field_option{id title titles{language_id translation}}' +
  ' related_entity{id values(locale:"%locale%"){id field_id language_id field_option{title} text string integer decimal bool date datetime}}';
const queryFields = `
    id
    entity_type_id
    parent{id variant_fields{id} values(locale:"%locale%"){id field_id language_id field_option{title} text string integer decimal bool date datetime}}
    variant_fields{id}
    values{${queryValueFields}}
  `;
export const getQueryFields = (language) => {
  return queryFields.replace('%locale%', language.locale);
}
export const getQueryValueFields = (language) => {
  return queryValueFields.replace('%locale%', language.locale);
}

export const EntityContext = createContext(null);

export default function EntityLayoutEditor(props) {
  const intl = useIntl();
  const {language, setLanguage} = useContext(LanguageContext);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  let id = params?.id;
  let entityTypeID = params?.entityTypeID;
  let relatedEntityId = parseInt(searchParams.get('related'));
  let relatedFieldId = parseInt(searchParams.get('related_field'));

  const [languages, setLanguages] = useState([]);
  const [settings, setSettings] = useState({
    language: '',
    locale: '',
    country_code: '',
    language_selector: false,
    revisions: false,
    hasLabels: false,
    hasPrints: false,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [notification, setNotification] = useState({open: false, message: ""});
  const [revisionsListDialogOpen, setRevisionsListDialogOpen] = useState(false);
  const [showPrintout, setShowPrintout] = useState(false);
  const [showExportLabel, setShowExportLabel] = React.useState(false);
  const [revision, setRevision] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [editing, setEditing] = useState(null);
  const [entityTypes, setEntityTypes] = useState([]);
  const [layouts, setLayouts] = useState([]);
  const [changes, setChanges] = useState([]);
  const [changesDialog, setChangesDialog] = useState(false);
  const [entity, setEntity] = React.useState({
    id: null,
    entity_type_id: entityTypeID,
    values: [],
    parent: null,
    variant_fields: [],
  });
  const getEntityValues = (fieldId) => {
    let values = changes.filter(v => v.field_id === fieldId);
    // console.log(values, entity.values.filter(v => v.field_id === fieldId))
    return values.length ? values : entity.values.filter(v => v.field_id === fieldId);
  }
  const setEntityValues = (fieldId, values) => {
    // console.log(values);
    setChanges(cur => {
      // Get an array of all existing changed values of other fields and append the new changed values, adding the field and language ids
      let n = [
        ...cur.filter(v => !(v.field_id === fieldId && v.language_id === (fields[fieldId]?.uses_languages ? language.id : null))),
        ...values.map(v => ({
          ...v,
          field_id: fieldId,
          language_id: fields[fieldId]?.uses_languages ? language.id : null
        }))
      ];
      // console.log(n.filter(v => v.field_id === fieldId));
      // Filter only changes that are actually new or different than the existing values
      n = n.filter(v => {
        // Do not check values that are not for this field or language
        // console.log(v.field_id, v.field_id !== fieldId, v.language_id !== language.id);
        if (v.field_id !== fieldId || v.language_id !== language.id || fields[v.field_id].type.match(/(entities_\d+|options|assets)/)) return true;
        let evs = entity.values.filter(ev => ev.field_id === fieldId && ev.language_id === v.language_id);
        let vV = getEntityValueFromField(fields[v.field_id], v, language.id);
        // If this is a new value that is not empty return as it is a change
        // console.log(evs, vV)
        if (!evs.length) return !!vV;
        // Figure out if the change is actually different than the existing value
        let differs = false;
        evs.forEach(ev => {
          let evV = getEntityValueFromField(fields[v.field_id], ev, language.id);
          // console.log(fields[fieldId].name, vV, evV)
          if ((vV?.id && vV.id !== evV?.id) || (vV !== evV)) {
            differs = true;
          }
        });
        return differs;
      });
      return n;
    });
    // setEntity(cur => {
    //   return {
    //     ...cur,
    //     values: [
    //       ...cur.values.filter(v => v.field_id !== fieldId),
    //       ...values
    //     ]
    //   }
    // });
  }
  const getChangesByField = () => {
    const fs = {};
    changes.forEach(c => {
      let k = "f" + c.field_id + "l" + c.language_id;
      if (!fs[k]) {
        fs[k] = {
          language_id: c.language_id,
          field: fields[c.field_id],
          existing: entity.values.filter(v => (v.field_id === c.field_id && v.language_id === c.language_id)),
          changes: [c],
        };
      } else fs[k].changes.push(c);
    });
    const result = [];
    for (let k in fs) {
      result.push(fs[k]);
    }
    return result;
  }

  const [fields, setFields] = useState({});
  const [tab, setTab] = useState(0);

  const fieldCols = 'id name key type uses_languages computation_value computation_language_id entity_type_id';

  const client = new graphQLApi(useAuthDispatch());
  useEffect(() => {
    setIsLoading(true);
    let query = '{' +
      (id > 0
        ? 'entities(filter:{id:' + id + '}){data{' + getQueryFields(language) + '}} '
        : 'entityDefaultValues(entity_type_id:' + entityTypeID + (params.hasOwnProperty('related_id') ? ' related_entity_id:' + params.related_id : '') + '){' + getQueryValueFields(language) + '}') +
      'settings(filter:{key_in:["language","country_code","locale","language_selector","revisions"]}){ data {key type data} }' +
      'fields{data{' + fieldCols + '}}' +
      'languages{data{ id name locale country_code }}' +
      'labels(filter:{entity_type_id:' + entityTypeID + '}){total}' +
      'printoutLayouts(filter:{entity_type_id:' + entityTypeID + '}){total}' +
      'entityTypeLayouts(filter:{entity_type_id:' + entityTypeID + '}){data{id title layout}}' +
      'entityTypes{data{roles{id} id title' +
      ' title_field{' + fieldCols + '}' +
      ' number_field{' + fieldCols + '}' +
      ' third_field{' + fieldCols + '}' +
      ' fourth_field{' + fieldCols + '}' +
      ' fifth_field{' + fieldCols + '}' +
      ' sixth_field{' + fieldCols + '} ' +
      ' views{id title listing_id columns{field{id type name key}}}}' +
      '}' +
      '}';
    client.query(query).then(r => {
      if (!r) return;
      if (r.hasOwnProperty('settings')) {
        let set = {};
        r.settings.data.forEach(s => {
          set[s.key] = s.data;
        });
        set.hasLabels = r.labels?.total > 0;
        set.hasPrints = r.printoutLayouts?.total > 0;
        setSettings({...settings, ...set});
      }
      if (r.hasOwnProperty('languages')) {
        setLanguages(r.languages.data);
      }
      if (r && r.hasOwnProperty('entityTypes')) {
        setEntityTypes(r.entityTypes.data);
      }
      if (r && r.hasOwnProperty('entityTypeLayouts')) {
        setLayouts(r.entityTypeLayouts.data);
      }
      const newFields = {}
      if (r && r.hasOwnProperty('fields')) {
        r.fields.data.forEach(f => {
          newFields[f.id] = f;
        })
        setFields(newFields);
      }

      if (r.hasOwnProperty('entities')) {
        setEntity(r.entities.data[0]);
      } else if (r.hasOwnProperty('entityDefaultValues')) {
        setEntity({...entity, values: r.entityDefaultValues});
      }
      setIsLoading(false);
    });
  }, [id, entityTypeID]);

  useEffect(() => {
    setTab(Number(searchParams.get('tab')) || 0);
  }, [layouts]);

  const entityType = entityTypes.find(t => t.id === entityTypeID);

  const canEdit = (field) => {
    let result = false;
    let et = entityTypes.find(e => e.id === field.entity_type_id);
    if (!et) return result;
    authUser().roles.filter(r => et.roles.find(er => parseInt(er.id) === r.id)).forEach(r => {
      if (r.priority <= rolePriorities.can_data)
        result = true;
    });
    return result;
  }

  const saveEntity = (force = false, redirect = true) => {
    const args = {
      parent_id: 'ID',
      entity_type_id: 'ID!',
      values: '[EntityValueInput]',
    };
    const data = {
      parent_id: entity.parent ? entity.parent.id : null,
      entity_type_id: entityTypeID,
      values: [],
    };
    if (settings.revisions === '1') {
      if (!force) {
        setChangesDialog(true);
        return;
      }
      args.revision = 'String';
      data.revision = revision;
    }
    if (id > 0) {
      args.id = 'ID!';
      data.id = id;
    } else if (relatedEntityId > 0) {
      args.related_to_entity_id = 'ID!';
      data.related_to_entity_id = relatedEntityId;
      args.related_to_field_id = 'ID';
      data.related_to_field_id = relatedFieldId;
    }
    changes.forEach(value => {
      let v = {...value};
      if (!fields[v.field_id].uses_languages) {
        delete v.language_id;
      }
      delete v.related_entity;
      delete v.field_option;
      let col = getEntityValueColumnFromField(fields[v.field_id]);
      switch (col) {
        case 'bool':
          v.bool = !!v.bool;
          break;
        case 'decimal':
          v.decimal = parseFloat(v.decimal);
          break;
        case 'integer':
          v.integer = parseInt(v.integer);
          break;
        case 'date':
          v.date = moment(v.date).format('YYYY-MM-DD');
          break;
        case 'datetime':
          v.datetime = moment(v.datetime).format('YYYY-MM-DD HH:mm:ss');
          break;
        default:
      }
      data.values.push(v);
    });
    setIsSaving(true);
    client.mutation('entity', args, data, getQueryFields(language)).then(r => {
      if (r?.hasOwnProperty('response')) {
        if (id > 0) {
          setNotification({
            open: true, message: intl.formatMessage({
              id: "product.edit.snackbar.data_updated",
              defaultMessage: "Data was updated successfully"
            })
          });
        } else {
          setNotification({
            open: true, message: intl.formatMessage({
              id: "product.edit.snackbar.data_created",
              defaultMessage: "Data was created successfully"
            })
          });
          navigate(location.pathname.replace(/create$/, r.response.id), {replace: true});
        }
        setRevision("");
        setChanges([]);
        setEntity(r.response);
        if (redirect)
          navigate(location.pathname.replace(/\/entity\/.*$/, ''));
      }
      setIsSaving(false);
    });
  }

  function handleExportLabelClose(data) {
    if (!data) {
      setShowExportLabel(false);
    } else {
      setIsSaving(true);
      //console.log(data);
      client.mutate('($id:ID,$export_as:String,$copies:Int,$export_format_id:ID,$related_ids:[ID]){exportEntity(id:$id,export_as:$export_as,copies:$copies,export_format_id:$export_format_id,related_ids:$related_ids)}', data)
        .then(r => {
          if (r && r.hasOwnProperty('exportEntity')) {
            //console.log(r);
            if (data.download) {
              window.open(r.exportEntity, '_blank');
            }
          }
          setIsSaving(false);
        });
      setShowExportLabel(false);
    }
  }

  return <Box key={'entity-layout-editor'} ml={"-20px"} mr={"-20px"}><EntityContext.Provider value={{
    entity: entity,
    setEntity: setEntity,
    getValuesOfField: getEntityValues,
    setValuesOfField: setEntityValues,
    editing: editing,
    setEditing: setEditing,
    canEdit: canEdit,
  }}>
    <Sidebar
      {...props}
      removeSuffix={'/entity/' + id}
      pathPrefix={"/data/" + entityTypeID}
      routes={entityTypes.find(e => e.id === entityTypeID) ? [{
        title: entityTypes.find(e => e.id === entityTypeID)?.title,
        path: '',
      }, ...entityTypes.find(e => e.id === entityTypeID)?.views.map(v => ({
        path: "view/" + v.id,
        name: v.title,
        component: EntityList,
        icon: Ballot,
      }))] : []}
      bottomLinks={authUser().isAllowed(rolePriorities.can_import_export) && [
        {
          name: getLayouts(intl).admin.title,
          icon: getLayouts(intl).admin.icon,
          path: getLayouts(intl).admin.defaultRoute,
        }
      ]}
    />
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={notification.open}
      autoHideDuration={3000}
      onClose={() => setNotification({...notification, open: false})}
      message={notification.message}
    />
    {revisionsListDialogOpen && (
      <RevisionsListDialog
        open={revisionsListDialogOpen}
        onClose={() => setRevisionsListDialogOpen(false)}
        entityId={id}
      />)}
    {changesDialog && <Dialog maxWidth="lg" open={changesDialog} onClose={_ => setChangesDialog(false)}>
      <DialogTitle disableTypography style={{
        fontSize: "x-large",
        fontWeight: "bold"
      }}>{getChangesByField().length > 1
        ? intl.formatMessage({
          id: "entity.edit.dialog.changes.plural-title",
          defaultMessage: "Review the changes in {count} fields"
        }, {count: getChangesByField().length})
        : intl.formatMessage({
          id: "entity.edit.dialog.changes.singular-title",
          defaultMessage: "Review the change to 1 field"
        })}</DialogTitle>
      <TableContainer
        style={{maxHeight: "50vh", maxWidth: "60vw", borderBottom: "1px solid " + theme.palette.grey[200]}}>
        <Table size="small" stickyHeader style={{borderBottom: "1px solid black"}}>
          <TableHead>
            <TableRow>
              <TableCell width="16%">{intl.formatMessage({
                id: "entity.edit.dialog.changes.field",
                defaultMessage: "Field"
              })}</TableCell>
              <TableCell width="30%">{intl.formatMessage({
                id: "entity.edit.dialog.changes.existing",
                defaultMessage: "Existing values"
              })}</TableCell>
              <TableCell width="30%">{intl.formatMessage({
                id: "entity.edit.dialog.changes.new",
                defaultMessage: "New values"
              })}</TableCell>
              <TableCell width="4%"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{getChangesByField().map((change, index) =>
            <TableRow key={"changes-row-" + index}>
              <TableCell
                style={{verticalAlign: "top"}}>{getFieldLabel(change.field, change.language_id ? languages.find(l => l.id === change.language_id) : {
                ...settings,
                name: settings.language,
                id: null
              })}</TableCell>
              <TableCell style={{verticalAlign: "top"}}><RenderEntityValue entityValues={change.existing}
                                            field={change.field} fields={fields}
                                            entityTypes={entityTypes}/></TableCell>
              <TableCell style={{verticalAlign: "top"}}><RenderEntityValue entityValues={change.changes}
                                            field={change.field} fields={fields}
                                            entityTypes={entityTypes}/></TableCell>
              <TableCell align="right"><IconButton
                size="small"
                title={intl.formatMessage({id: "common.button.delete"})}
                onClick={_ => {
                  let nc = [...changes.filter(c => c.field_id !== change.field.id)];
                  setChanges(nc);
                  if (!nc.length) setChangesDialog(false);
                }}
              ><Clear/></IconButton></TableCell>
            </TableRow>
          )}</TableBody>
        </Table>
      </TableContainer>
      {settings.revisions === '1' && <DialogContent>
        <Typography variant="subtitle1">{intl.formatMessage({
          id: "entity.edit.dialog.changes.revision-heading",
          defaultMessage: "Write a description of your changes for the revision log"
        })}</Typography>
        <TextField
          label={intl.formatMessage({
            id: "entity.edit.dialog.changes.revision-message",
            defaultMessage: "Revision message"
          })}
          multiline
          minRows={4}
          fullWidth
          value={revision}
          onChange={e => setRevision(e.target.value)}
        />
      </DialogContent>}
      <DialogActions style={{display: "flex", justifyContent: "space-between"}}>
        <Button
          size="small"
          color="default"
          variant="contained"
          onClick={() => setChangesDialog(false)}
        ><FormattedMessage id={"common.button.close"}/></Button>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={_ => {
            setChanges([]);
            setChangesDialog(false);
          }}
        ><FormattedMessage id="entity.edit.dialog.changes.discard" defaultMessage="Discard changes"/></Button>
        {settings.revisions === '1' && <Box style={{display: "flex", justifyContent: "space-between", gap: 16}}>
          <Button
            disabled={revision.length < 8}
            size="small"
            variant="contained"
            color="primary"
            onClick={_ => {
              setChangesDialog(false);
              saveEntity(true, false);
            }}
          ><FormattedMessage id="common.button.save"/></Button>
          <Button
            disabled={revision.length < 8}
            size="small"
            variant="contained"
            color="primary"
            onClick={_ => {
              setChangesDialog(false);
              saveEntity(true);
            }}
          ><FormattedMessage id="common.button.save_and_close"/></Button>
        </Box>}
      </DialogActions>
    </Dialog>}
    {entity.values && showExportLabel &&
      <ExportLabel
        entity={entity}
        entityTypeID={entityTypeID}
        onClose={handleExportLabelClose}
        open={showExportLabel}
      />}
    {showPrintout &&
      <SelectPrintoutDialog
        open
        onClose={() => setShowPrintout(false)}
        entity={entity}
      />}
    <Box pl={"250px"} mt={"-22px"} hidden={isLoading}>
      <ActionBar
        justifyContent={"space-between"}
        style={{
          flexWrap: "nowrap",
        }}>
        <Grid item
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                gap: 24,
                color: themeColors.text.primary,
                paddingLeft: "1.5rem", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", minWidth: 0
              }}>
          {entityType?.number_field && entity?.values &&
            <RenderEntityValue
              field={entityType.number_field}
              style={{fontSize: "large"}} prepend={"#"}
            />}
          {entityType?.title_field && entity?.values &&
            <RenderEntityValue
              field={entityType.title_field}
              style={{
                fontWeight: "bold",
                fontSize: "large",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: 'ellipsis',
                minWidth: 0
              }}/>}
        </Grid>
        <Grid item style={{display: "flex", gap: 8, alignItems: "center"}}>
          {settings.language_selector &&
            <LanguageSelect language={language} setLanguage={setLanguage} languages={languages} settings={settings}/>}
          {authUser().isAllowed(rolePriorities.can_data) && changes.length > 0 && (isSaving
            ? <CircularProgress size={24}/>
            : [
              <Button
                size="small"
                color="secondary"
                variant="contained"
                onClick={_ => {
                  setEditing(null);
                  setChangesDialog(true);
                }}
              >{getChangesByField().length > 1
                ? <FormattedMessage
                  id={"common.button.discard_changes"}
                  defaultMessage={"{count} changes"}
                  values={{count: getChangesByField().length}}/>
                : <FormattedMessage
                  id={"common.button.discard_change"}
                  defaultMessage={"1 change"}/>
              }</Button>,
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => saveEntity(false, false)}
                disabled={(isLoading || isSaving)}>
                {(isLoading || isSaving) ? (
                  <CircularProgress size={17} color="inherit"/>
                ) : (
                  <FormattedMessage id={"common.button.save"}/>
                )}
              </Button>,
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => saveEntity()}
                disabled={(isLoading || isSaving)}>
                {(isLoading || isSaving) ? (
                  <CircularProgress size={17} color="inherit"/>
                ) : (
                  <FormattedMessage id={"common.button.save_and_close"}/>
                )}
              </Button>])}
          {!changes.length && [
            <Button
              size="small"
              color="secondary"
              variant="contained"
              disabled
              style={{whiteSpace: "nowrap"}}
            ><FormattedMessage
              id={"common.button.no_changes"}
              defaultMessage={"No changes"}
            /></Button>,
            <Button
              size="small"
              color="default"
              variant="contained"
              onClick={() => navigate(-1)}
              disabled={isLoading}>
              <FormattedMessage id={"common.button.close"}/>
            </Button>]}
          <ActionMenu
            isLoading={isLoading}
            settings={settings}
            setRevisionsListDialogOpen={setRevisionsListDialogOpen}
            setShowExportLabel={setShowExportLabel}
            setShowSelectLayout={setShowPrintout}
          />
        </Grid>
      </ActionBar>
      <AppBar
        position={"fixed"}
        color={"default"}
        variant={"outlined"}
        style={{zIndex: 1, top: 100, width: "100%", paddingLeft: 250}}
      >
        <Tabs
          style={{flexGrow: 1}}
          value={tab}
          onChange={(_, v) => {
            setTab(v);
            setSearchParams({...searchParams, tab: v}, {replace: true});
          }}
          indicatorColor={"primary"}
          textColor={"primary"}
          color={"default"}
          variant={"scrollable"}
          scrollButtons={"auto"}
          aria-label={"Entity type tabs"}
        >{layouts.map((t, index) =>
          <Tab
            key={"tab-button-" + index}
            {...a11yProps(index)}
            label={t.title}
            disabled={!id && index < layouts.length - 1}
            style={{minWidth: "auto"}}
          />
        )}</Tabs>
      </AppBar>
      <Box mt={"172px"} p={2}>{layouts.map((t, index) =>
        <LayoutTab
          key={"tab-layout-" + index}
          tabIndex={index}
          tabSelected={tab}
          layout={JSON.parse(t.layout)}
          data={entity.values}
          fields={fields}
          entityTypes={entityTypes}
          onChangeValue={() => {
          }}
        />
      )}</Box>
    </Box>
  </EntityContext.Provider></Box>;
}
