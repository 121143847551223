import {FormControl, IconButton, InputLabel} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React, {useContext} from "react";
import {EntityContext} from "../../EntityLayoutEditor";
import {Check} from "@material-ui/icons";
import {LanguageContext} from "../../../../contexts/Layouts";
import {FieldTypes, getEntityValueFromFieldType} from "../../../../variables/fields";
import AssetsField from "./AssetsField";
import AssetField from "./AssetField";
import ButtonField from "./ButtonField";
import StringField from "./StringField";
import BooleanField from "./BooleanField";
import DateTimeField from "./DateTimeField";
import DateField from "./DateField";
import HtmlField from "./HtmlField";
import TextareaField from "./TextareaField";
import NumberField from "./NumberField";
import DecimalField from "./DecimalField";
import PriceField from "./PriceField";
import CompletionField from "./CompletionField";
import OptionField from "./OptionField";
import OptionsField from "./OptionsField";

export function getFieldLabel(field, language) {
  if (field.uses_languages) {
    return <div style={{display: "inline-flex", gap: 6, alignItems: "center"}}>
      <img
        height="16"
        alt={language.name}
        src={"/flags/" + language.country_code.toUpperCase() + ".png"}
        title={language.name}/>
      <div>{field.name}</div>
    </div>
  }
  return field.name
}

export function getEntityTitle(entity, fields, allFields) {
  if (!entity) {
    console.warn('Unable to get entity title of an empty entity', entity, fields);
    return '';
  }
  const ret = ['#' + entity.id];
  fields.forEach(id => {
    let v = entity.values.find(v => v.field_id === id);
    if (v && !v.field && allFields) v.field = allFields[v.field_id];
    ret.push(getEntityValueFromFieldType(v));
  })
  return ret.join(' - ');
}

export function RenderFieldOfType({field}) {
  switch (field?.type) {
    case FieldTypes.DATE:
      return <DateField field={field}/>;
    case FieldTypes.DATETIME:
      return <DateTimeField field={field}/>;
    case FieldTypes.BOOLEAN:
    case FieldTypes.COMPUTED_IS_NULL:
    case FieldTypes.COMPUTED_EQUAL:
    case FieldTypes.COMPUTED_NOT_EQUAL:
    case FieldTypes.COMPUTED_GREATER:
    case FieldTypes.COMPUTED_GREATER_EQUAL:
    case FieldTypes.COMPUTED_LESS:
    case FieldTypes.COMPUTED_LESS_EQUAL:
    case FieldTypes.COMPUTED_IS_BLANK:
    case FieldTypes.COMPUTED_SEARCH:
    case FieldTypes.COMPUTED_SAME:
    case FieldTypes.COMPUTED_DIFFERENT:
      return <BooleanField field={field}/>;
    case FieldTypes.STRING:
    case FieldTypes.COMPUTED_SCRIPT_STRING:
      return <StringField field={field}/>;
    case FieldTypes.COMPUTED_BUTTON:
      return <ButtonField field={field}/>;
    case FieldTypes.ASSET:
      return <AssetField field={field}/>;
    case FieldTypes.ASSETS:
      return <AssetsField field={field}/>;
    case FieldTypes.HTML:
      return <HtmlField field={field}/>;
    case FieldTypes.TEXT:
    case FieldTypes.COMPUTED_APPEND:
    case FieldTypes.COMPUTED_PREFIX:
    case FieldTypes.COMPUTED_JOIN:
    case FieldTypes.COMPUTED_TRIM:
    case FieldTypes.COMPUTED_LOWER:
    case FieldTypes.COMPUTED_UPPER:
    case FieldTypes.COMPUTED_TITLE:
    case FieldTypes.COMPUTED_STUDLY:
    case FieldTypes.COMPUTED_CAMEL:
    case FieldTypes.COMPUTED_SNAKE:
    case FieldTypes.COMPUTED_SLUG:
    case FieldTypes.COMPUTED_SCRIPT_TEXT:
      return <TextareaField field={field}/>;
    case FieldTypes.COMPUTED_AUTO_INC:
    case FieldTypes.NUMBER:
      return <NumberField field={field}/>;
    case FieldTypes.PRICE:
      return <PriceField field={field}/>;
    case FieldTypes.COMPUTED_SUM:
    case FieldTypes.COMPUTED_AVERAGE:
    case FieldTypes.COMPUTED_MAX:
    case FieldTypes.COMPUTED_MIN:
    case FieldTypes.COMPUTED_MULTIPLY:
    case FieldTypes.COMPUTED_DIVIDE:
    case FieldTypes.COMPUTED_SUBTRACT:
    case FieldTypes.COMPUTED_ROUND:
    case FieldTypes.DECIMAL:
      return <DecimalField field={field}/>;
    case FieldTypes.COMPUTED_COMPLETION:
      return <CompletionField field={field}/>;
    case FieldTypes.OPTION:
      return <OptionField field={field}/>;
    case FieldTypes.OPTIONS:
      return <OptionsField field={field}/>;
    default:
      return <em>Unknown field type: {field.type} of field #{field.id}</em>;
  }
}

export function RenderEditableField({
                                      field,
                                      value,
                                      onEditing,
                                      minHeight = 42.45,
                                      closingKeys = ['Enter', 'Escape'],
                                      ...rest
                                    }) {
  const {language} = useContext(LanguageContext);
  const {editing, setEditing, canEdit} = useContext(EntityContext);
  const isEditable = () => {
    return ['variant', 'parent'].includes(field?.id) || (field?.type?.search(/^computation_.+$/) === -1 && canEdit(field))
  }

  return editing === field.id
    ? <Box
      display="flex"
      style={{gap: 8, paddingTop: 6}}
      width="100%"
      alignItems="center"
      onKeyUp={e => closingKeys.includes(e.code) && setEditing(null)}
    >
      {rest.children}
      <Box><IconButton size="small" onClick={_ => setEditing(null)}><Check/></IconButton></Box>
    </Box>
    : <FormControl
      fullWidth
      variant="standard"
      style={{cursor: !isEditable() ? "auto" : "pointer", display: "flex"}}
      onClick={e => {
        if (!isEditable()) return;
        setEditing(field.id);
        if (onEditing) onEditing(e);
      }}
    >
      <InputLabel shrink style={{cursor: !isEditable() ? "auto" : "pointer"}}>{
        getFieldLabel(field, language)
      }</InputLabel>
      <Box minHeight={minHeight} pt={2} display="flex" gridGap={6}>{value}</Box>
    </FormControl>
}
